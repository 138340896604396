const ValidationService = {
    ValidEmail: function (emailInput) {
        var emailValidationFormat = /^[^\s@]+@[^\s@]{2,}\.[^\s@]{2,}$/;

        const found = emailInput.match(emailValidationFormat);        
        return found !== null; 
    },
    HasInvalidValue: function(inputValue, invalidValue) {
        if(typeof inputValue === 'undefined' || inputValue === null || inputValue.toString() === invalidValue.toString()){
            return true;
        }

        return false;
    }
}

export default ValidationService;