import React, { Component } from 'react';
import { MsalContext } from '@azure/msal-react';
import { getAccessToken } from '../../services/authService';
import TextConversionService from '../../services/textConversionService';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ContactEdit } from './ContactEdit';
import { ContactDelete } from './ContactDelete';
import { Toaster } from '../common/Toaster';

export class Contacts extends Component {
  static displayName = Contacts.name;
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.editModalRef = React.createRef();
    this.deleteModalRef = React.createRef();
    this.toasterRef = React.createRef();
  }

  getInitialState() {
    const searchParams = new URLSearchParams(this.props.location.search);
    let initPageSize = '50';
    const pageSizeSearchParam = searchParams.get('pageSize');
    if (pageSizeSearchParam) {
      initPageSize = pageSizeSearchParam;
    }

    let initPage = '1';
    const pageSearchParam = searchParams.get('page');
    if (pageSearchParam) {
      initPage = pageSearchParam;
    }

    const filters = {
      page: initPage,
      pageSize: initPageSize,
    }


    return { contacts: [], loading: true, filters: filters, selectedContacts: [], showEditModal: false, activeContact: null };
  }

  componentDidMount() {
    this.loadContacts(this.state.filters.page);
  }

  handleModalClose(updatedContact, message) {
    this.editModalRef.current.toggleModal(false);
    if (typeof (updatedContact) !== 'undefined' && updatedContact !== null) {
      let contacts = [...this.state.contacts];
      let contactUpdated = false;
      for (let index = 0; index < contacts.length; index++) {
        let c = contacts[index];
        if (c.id === updatedContact.id) {
          contacts[index] = updatedContact; 
          contactUpdated = true;
          break; 
        }
      }
      if(!contactUpdated) {
        contacts.push(updatedContact);
      }

      this.setState({ contacts: contacts, showEditModal: false });
    } else {
      this.setState({ showEditModal: false });
    }
    if(message){
      this.toasterRef.current.addMessage(message, "Kontakt");
    }
  }

  openEditForm() {
    this.setState({ showEditModal: true });
    this.editModalRef.current.toggleModal(true);
  }

  async onEditContactHandler(event, contactId) {
    this.setState({ showEditModal: true });
    this.editModalRef.current.toggleModal(true);
    await this.editModalRef.current.loadContact(contactId);
  }

  async onDeleteContactHandler(event, contactId, recipient) {
    this.setState({ showDeleteModal: true, deleteContactId: contactId });
    this.deleteModalRef.current.setupDelete(contactId, recipient);
    this.deleteModalRef.current.toggleModal(true);
  }

  handleDeleteModalClose(event, contactDeleted, message) {
    this.deleteModalRef.current.toggleModal(false);
    if (contactDeleted) {
      let spliceIndex = -1;
      let contacts = [...this.state.contacts];
      for (let index = 0; index < contacts.length; index++) {
        let c = contacts[index];
        if (c.id === this.state.deleteContactId) { spliceIndex = index; break; }
      }
      if (spliceIndex > -1) {
        contacts.splice(spliceIndex, 1);
      }
      this.setState({ contacts: contacts, showDeleteModal: false, deleteContactId: null });
      return;
    }
    if(message){
      this.toasterRef.current.addMessage(message, "Radera kontakt");
    }
    this.setState({ showDeleteModal: false, deleteContactId: null });
  }

  renderTable(contacts) {
    return (
      <table className='table table-striped table-hover' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Hantera</th>
            <th>Mottagare</th>
            <th>Adress</th>
            <th>E-post</th>
            <th>Hedersperson</th>
          </tr>
        </thead>
        <tbody>
          {contacts && contacts.length > 0 && contacts.map(contact =>
            <tr key={contact.id}>
              <td>
                <div className='button-container'>
                  <Button title='Redigera kontakt' onClick={(event) => this.onEditContactHandler(event, contact.id)} variant='outline-primary'><FontAwesomeIcon icon={faEdit} /></Button>
                  <Button title='Radera kontakt' onClick={(event) => this.onDeleteContactHandler(event, contact.id, contact.recipient)} variant='outline-dark'><FontAwesomeIcon icon={faTrashAlt} /></Button>
                </div>
              </td>
              <td>{contact.recipient}</td>
              <td dangerouslySetInnerHTML={{ __html: TextConversionService.FormatForWeb(contact.recipientAddress, ", ", ", ") }}></td>
              <td>{contact.email}</td>
              <td>{contact.honoraryName}</td>
            </tr>
          )}
          {
            (!contacts || contacts.length === 0) &&
            <tr>
              <td colSpan='5' className='text-center'>Inga kontakter hittades</td>
            </tr>
          }
        </tbody>
      </table>
    );
  }

  renderEditModal() {
    return (
      <ContactEdit ref={this.editModalRef} contactId={this.state.activeContact?.id} show={this.state.showEditModal} notifyParent={this.handleModalClose}  ></ContactEdit>
    )
  }

  renderDeleteModal() {
    return (
      <ContactDelete ref={this.deleteModalRef} deleteContactId={this.state.deleteContactId} notifyParent={this.handleDeleteModalClose} />
    )
  }


  render() {
    let table = this.state.loading ?
      <Spinner animation="border" variant="primary" />
      : this.renderTable(this.state.contacts);

    let editModal = this.renderEditModal();
    let deleteModal = this.renderDeleteModal();

    return (
      <div>
        <Toaster ref={this.toasterRef} />
        <h1>Kontakter</h1>
        <div className='row'>
          <div className='col'>
            <Button variant='success' title='Skapa ny kontakt' onClick={(event) => this.openEditForm()}  ><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Skapa ny kontakt</Button>
          </div>
        </div>
        {table}
        {editModal}
        {deleteModal}
      </div>
    );
  }

  async loadContacts(page) {
    let url = new URL('api/contacts', document.baseURI);
    let params = Object.assign({}, this.state.filters);
    params.page = page;
    url.search = new URLSearchParams(params).toString();

    const myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');
    myHeaders.append('Authorization', `Bearer ${await getAccessToken(this.context)}`);

    const init = {
      method: 'GET',
      headers: myHeaders
    };

    const response = await fetch(url, init);
    const data = await response.json();
    this.setState({ contacts: response.ok ? data.items : [], totalFound: response.ok ? parseInt(data.totalFound) : 0, loading: false, filters: params });
  }


}
