import React from "react";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import PdfTemplatesSettings from "./pdftemplatessettings/PdfTemplatesSettings";
import { MsalContext } from "@azure/msal-react";
import { getAccessToken } from "../services/authService";
import IndexStatus from "./indexstatus/IndexStatus";

export class Settings extends React.Component {
    static contextType = MsalContext;

    constructor(props) {
        super(props)
        this.state = { rebuildingDonationIndex: false, rebuildingDonationIndexComplete: false, rebuildError: false };
        this.onRebuildDonationIndex = this.onRebuildDonationIndex.bind(this);
        this.onRebuildContactIndex = this.onRebuildContactIndex.bind(this);
        this.clearToast = this.clearToast.bind(this);
        this.notifyMessage = this.notifyMessage.bind(this);
    }

    async onRebuildDonationIndex(event) {
        this.setState({ rebuildingDonationIndex: true, rebuildingDonationIndexComplete: false, rebuildError: false });
        let url = new URL(`api/donationindexmanagement/rebuild`, document.baseURI);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            },
            method: 'POST'
        });
        if (response.ok) {
            this.setState({ rebuildingDonationIndex: false, rebuildingDonationIndexComplete: true, rebuildError: false });
        }
        else {
            this.setState({ rebuildingDonationIndex: false, rebuildingDonationIndexComplete: false, rebuildError: true });
        }
    }

    async onRebuildContactIndex(event) {
        this.setState({ rebuildingContactIndex: true, rebuildingContactIndexComplete: false, rebuildContactError: false });
        let url = new URL(`api/contactsindexmanagement/rebuild`, document.baseURI);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            },
            method: 'POST'
        });
        if (response.ok) {
            const notify = {
                text: "Kontaktindex ombyggt",
                headline: "Kontakter"
            }
            this.setState({ rebuildingContactIndex: false, rebuildingContactIndexComplete: true, rebuildContactError: false, notify: notify });
        }
        else {
            const notify = {
                text: "Ombyggnad av Kontaktindex misslyckades",
                headline: "Kontakter"
            }
            this.setState({ rebuildingContactIndex: false, rebuildingContactIndexComplete: false, rebuildContactError: true, notify: notify });
        }
    }

    notifyMessage(text, headline) {
        this.setState({ notify: { text, headline } })
    }

    clearToast(event) {
        this.setState({ rebuildingDonationIndex: false, rebuildingDonationIndexComplete: false, rebuildError: false, notify: null });
    }

    renderToast(message, header, error = false) {
        return (
            <Toast onClose={this.clearToast} delay={3000} autohide>
                <Toast.Header>
                    <FontAwesomeIcon className={error ? 'alert' : 'info'} icon={error ? faExclamationTriangle : faInfoCircle}></FontAwesomeIcon>
                    {header && <strong className="ps-2 me-auto">{header}</strong>}
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        );
    }

    render() {
        return (
            <>
                <ToastContainer position="top-center" className="p-2">
                    {this.state.rebuildingDonationIndexComplete && !this.state.rebuildError && this.renderToast("Sökindex är ombyggt", "Gåvoregister")}
                    {this.state.rebuildingDonationIndexComplete && this.state.rebuildError && this.renderToast("Ombyggnad av sökindex misslyckades", "Gåvoregister")}
                    {this.state.notify && this.renderToast(this.state.notify.text, this.state.notify.headline)}
                </ToastContainer>
                <Container as='main' fluid={true} >
                    <h1 className='mb-4'>Inställningar</h1>
                    <div className='row mb-4'>
                        <h2>Sökindex</h2>
                        <div className='col-2'>
                            <Button onClick={this.onRebuildDonationIndex} disabled={this.state.rebuildingDonationIndex} >Bygg om sökindex för gåvor</Button> {this.state.rebuildingDonationIndex && <Spinner animation="border" variant="primary" />}
                            <IndexStatus></IndexStatus>
                        </div>
                        <div className='col-2'>
                            <Button onClick={this.onRebuildContactIndex} disabled={this.state.rebuildingContactIndex} >Bygg om sökindex för kontakter</Button> {this.state.rebuildingContactIndex && <Spinner animation="border" variant="primary" />}

                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <PdfTemplatesSettings notify={this.notifyMessage} ></PdfTemplatesSettings>
                        </div>
                    </div>
                </Container>
            </>
        );
    }
}