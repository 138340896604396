export default class DonationDto {
    id;
    donorId; 
    donorCompanyName;       
    donorFirstName;
    donorLastName;
    donorAddressId;
    donorAddress1;
    donorAddress2;
    donorEmail;
    donorZip;
    donorCity;
    donorIdentificationNumber;
    isCompany;

    recipient;
    recipientAddressId;
    recipientAddress1;
    recipientAddress2;
    recipientZip;
    recipientCity;
    recipientEmail;

    honoraryFirstName;
    honoraryLastName
    honoraryMessage;
    honoraryMessageFrom;
    honoraryDateUtc;
    imageType;
    imageReference;
    comments;    
    clearSent;   
    donationDelivery; 

}