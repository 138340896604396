import { Component } from "react";
import { Spinner } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { MsalContext } from "@azure/msal-react";
import { getAccessToken } from "../../services/authService";

export class DonationPaymentModal extends Component {
    static contextType = MsalContext;
    parentModalCloseHandler = null;

    constructor(props) {
        super(props);

        this.state = { showPaymentModal: props.show, loading: true, donationId: props.donationId, paymentUrl: '' }

        if (props.notifyParent) {
            this.parentModalCloseHandler = props.notifyParent;
        }

    }

    componentDidMount() {
        console.log("mount", this.state)
        if (this.state.showPaymentModal && this.state.donationId > 0 && this.state.paymentUrl === '') {
            this.loadpaymentUrl();
        }
    }

    componentDidUpdate() {

    }

    async loadpaymentUrl() {
        let url = new URL(`api/donationedit/paymenturl/${this.state.donationId}`, document.baseURI);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            }
        }).catch(error => console.log("Error loading memory verses", error));
        if (response.ok) {
            try {
                const data = await response.json();
                this.setState({ paymentUrl: data.paymentUrl, loading: false, error: null });
            } catch {
                this.setState({ paymentUrl: '', loading: false, error: "Fel uppstod när betalningen skulle sättas upp." });
            }
        }
        else {
            this.setState({ paymentUrl: '', loading: false, error: "Fel uppstod när betalningen skulle sättas upp." });
        }
    }


    handleModalClose(event, updatedDonation, message) {
        this.setState({ donationId: null, showPaymentModal: false, loading: false });
        if (typeof (this.parentModalCloseHandler) === 'function') {
            this.parentModalCloseHandler(updatedDonation, message);
        }
    }

    render() {
        let contents = this.state.loading
            ? <Spinner animation="border" variant="primary"><span className="visually-hidden">Laddar gåva...</span></Spinner> :
            <iframe src={this.state.paymentUrl} seamless title="Betalning av gåva" width={'100%'} height={'600px'} scrolling="auto"></iframe>

        return (
            <Modal show={this.state.showPaymentModal} onHide={(event) => this.handleModalClose(event, this.state.donationId, null)} size="xl" >
                <Modal.Header closeButton>
                    <Modal.Title>Betala gåva</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {contents}
                </Modal.Body>
                <Modal.Footer>
                    {this.state.error &&
                        <Alert variant="danger">
                            <div dangerouslySetInnerHTML={{ __html: this.state.error }}></div>
                        </Alert>
                    }
                    <Button variant="primary" onClick={(event) => this.handleModalClose(event, this.state.donationId, null)}>
                        Stäng betalfönster
                    </Button>

                </Modal.Footer>
            </Modal >
        )
    }
}