import React, { Component } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TextConversionService from "../../services/textConversionService";
import { MsalContext } from "@azure/msal-react";
import { getAccessToken } from "../../services/authService";
import { Alert } from "react-bootstrap";

export class ContactEdit extends Component {
    static contextType = MsalContext;
    parentModalCloseHandler = null;

    constructor(props) {
        super(props);        
        this.state = { showModal: props.show, contactId: props.contactId, loading: false, contactDto: {} };
        if (props.notifyParent) {
            this.parentModalCloseHandler = props.notifyParent;
        }

        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleFormEdit = this.handleFormEdit.bind(this);
        this.saveContactDto = this.saveContactDto.bind(this);
        this.loadContact = this.loadContact.bind(this);
    }

    componentDidMount() {        
        if(this.state.contactId && !this.state.contactDto ) {
            this.loadContact(this.state.contactId);
        }
    }  

    handleModalClose(event, updatedContact, message) {            
        if (typeof (this.parentModalCloseHandler) === 'function') {
            this.parentModalCloseHandler(updatedContact, message);
        }

        this.setState({ contactId: null, loading: false, showModal: false, contactDto: {} });      
    }

    async loadContact(contactId) {
        this.setState({loading: true});
        let url = new URL(`api/contacts/${contactId}`, document.baseURI);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            },
            method: 'GET'        
        });
        if (response.ok) {
            let contact = await response.json();            
            let contactDto = {
                dateCreatedUtc: contact.dateCreatedUtc,
                recipient:  contact.recipient,
                honoraryFirstName: contact.honoraryFirstName,
                honoraryLastName: contact.honoraryLastName,
                id: contact.id,
                address1: contact.address?.address1,
                address2: contact.address?.address2,
                zip: contact.address?.zip,
                city: contact.address?.city,
                email: contact.address?.email
            }

            this.setState({loading: false, contactDto: contactDto});
        }
        else {
            let errorMessages = await TextConversionService.DisplayErrorFromResponse(response);
            this.setState({error: errorMessages, loading: false});
        }
    }

    async saveContactDto() {
        if (typeof (this.state.contactDto !== 'undefined')) {
            let url = new URL(this.state.contactDto.id > 0 ? `api/contacts/${this.state.contactDto.id}` : `api/contacts`, document.baseURI);
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getAccessToken(this.context)}`
                },
                method: this.state.contactDto.id > 0 ? 'PUT' : 'POST',
                body: JSON.stringify(this.state.contactDto)
            });
            if (response.ok) {
                let contactDto = await response.json();
                this.handleModalClose(null, contactDto, "Kontakten sparades");
            }
            else {
                let errorMessages = await TextConversionService.DisplayErrorFromResponse(response);
                this.setState({error: errorMessages});
            }
        }        
    }

    toggleModal(show) {
        this.setState({showModal: show});
    }

    handleFormEdit(event, properytName) {

        let contactDto = Object.assign({}, this.state.contactDto);

        contactDto[properytName] = event.target.value;

        this.setState({ contactDto: contactDto });
    }


    renderContactForm() {
        const mandStyle = {
            color: 'red'
        }
        return (
            <>
                <h3>Kontakt {this.state.contactDto?.id}</h3>
                <div className='donationInfo'>
                    <div>
                        Skapad: {TextConversionService.FormatDate(Date.parse(this.state.contactDto?.dateCreatedUtc ?? new Date().toISOString()))}
                    </div>
                </div>
                {this.state.error && <Alert variant="danger" >{this.state.error}</Alert>}
                <Form className='donationEditForm'>
                    <Row>
                        <Col md={6} sm={12} >
                            <h4>Mottagare</h4>
                            <Row>
                                <Col>
                                    <Form.Group controlId='recipientName'>
                                        <Form.Label>Namn <span style={mandStyle}>*</span></Form.Label>
                                        <Form.Control value={this.state.contactDto.recipient || ''} onChange={(event) => this.handleFormEdit(event, 'recipient')} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group controlId='recipientAddress1'>
                                    <Form.Label>Address <span style={mandStyle}>*</span></Form.Label>
                                    <Form.Control value={this.state.contactDto.address1 || ''} onChange={(event) => this.handleFormEdit(event, 'address1')}  ></Form.Control>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group controlId='recipientAddress2'>
                                    <Form.Label>Address 2</Form.Label>
                                    <Form.Control value={this.state.contactDto.address2 || ''} onChange={(event) => this.handleFormEdit(event, 'address2')}  ></Form.Control>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId='recipientZip'>
                                        <Form.Label>Postnr <span style={mandStyle}>*</span></Form.Label>
                                        <Form.Control value={this.state.contactDto.zip || ''} onChange={(event) => this.handleFormEdit(event, 'zip')}   ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={8}>
                                    <Form.Group controlId='recipientCity'>
                                        <Form.Label>Stad <span style={mandStyle}>*</span></Form.Label>
                                        <Form.Control value={this.state.contactDto.city || ''} onChange={(event) => this.handleFormEdit(event, 'city')} ></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId='recipientEmail'>
                                        <Form.Label>E-post</Form.Label>
                                        <Form.Control type='email' value={this.state.contactDto.email || ''} onChange={(event) => this.handleFormEdit(event, 'email')} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>Hedersperson</h5>
                            <Row>
                                <Col md={3}>
                                    <Form.Group controlId='honoraryFirstName'>
                                        <Form.Label>Förnamn</Form.Label>
                                        <Form.Control value={this.state.contactDto.honoraryFirstName || ''} onChange={(event) => this.handleFormEdit(event, 'honoraryFirstName')} />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group controlId='honoraryLastName'>
                                        <Form.Label>Efternamn</Form.Label>
                                        <Form.Control value={this.state.contactDto.honoraryLastName || ''} onChange={(event) => this.handleFormEdit(event, 'honoraryLastName')} />
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Form>
            </>
        )
    }

    render() {
        let contents = this.state.loading
            ? <Spinner animation="border" variant="primary"><span className="visually-hidden">Laddar kontakt...</span></Spinner> :
            this.renderContactForm();        

        return (
            <Modal show={this.state.showModal} onHide={(event) => this.handleModalClose(event, null, null)} size="xl" >
                <Modal.Header closeButton>
                    <Modal.Title>Redigera kontakt</Modal.Title>
                </Modal.Header>
                <Modal.Body>{contents}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(event) => this.handleModalClose(event, null, null)}>
                        Avbryt
                    </Button>
                    <Button variant="primary" onClick={this.saveContactDto}>
                        Spara
                    </Button>
                </Modal.Footer>
            </Modal >

        )
    }
}