import { Component } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert';
import './DonationEdit.scss';
import TextConversionService from '../../services/textConversionService';
import DonationDto from './DonationDto';
import DonationCreateDto from './DonationCreateDto';
import { MsalContext } from "@azure/msal-react";
import { getAccessToken } from "../../services/authService";
import { ContactSelect } from "../common/ContactSelect";
import { faLock, faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidationService from "../../services/validationService";
import InputGroup from 'react-bootstrap/InputGroup'


export class DonationEdit extends Component {


    static contextType = MsalContext;
    parentModalCloseHandler = null;
    shouldClearSent = false;
    AnonymousID = '169b2133-0d2a-4220-a4d1-4d4775d9877a';

    constructor(props) {
        super(props);

        this.state = { donationId: props.donationId, donation: null, loading: !props.newDonation, showModal: props.show, editSent: false, shouldClearSent: false, newDonation: props.newDonation, donationDto: props.newDonation ? this.getCreateDefaults() : null, memoryImages: [], celebrationImages: [], memoryVerses: [], celebrationVerses: [], addressError: '' };

        if (props.notifyParent) {
            this.parentModalCloseHandler = props.notifyParent;
        }
        this.handleFormEdit = this.handleFormEdit.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.saveDonationDto = this.saveDonationDto.bind(this);
        this.updateRecipient = this.updateRecipient.bind(this);
        this.showClearSent = this.showClearSent.bind(this);
        this.handleShouldClearSent = this.handleShouldClearSent.bind(this);
        this.convertDonationToDto = this.convertDonationToDto.bind(this);
        this.addError = this.addError.bind(this);
        this.clearError = this.clearError.bind(this);
        this.loadMemoryImages = this.loadMemoryImages.bind(this);
        this.loadCelebrationImages = this.loadCelebrationImages.bind(this);
        this.loadMemoryVerses = this.loadMemoryVerses.bind(this);
        this.loadCelebrationVerses = this.loadCelebrationVerses.bind(this);
        this.setAnonymousDonor = this.setAnonymousDonor.bind(this);
        this.loadSparAdress = this.loadSparAdress.bind(this);

    }

    async componentDidMount() {
        if (this.state.memoryImages.length === 0) {
            await this.loadMemoryImages();
        }

        if (this.state.memoryVerses.length === 0) {
            await this.loadMemoryVerses();
        }

        if (this.state.celebrationImages.length === 0) {
            await this.loadCelebrationImages();
        }

        if (this.state.celebrationVerses.length === 0) {
            await this.loadCelebrationVerses();
        }

        if (!this.state.newDonation) {
            this.loadFullDonation();
        }
    }


    handleModalClose(event, updatedDonation, message) {
        this.setState({ donationId: null, donation: null, loading: false, showModal: false });
        if (typeof (this.parentModalCloseHandler) === 'function') {
            this.parentModalCloseHandler(updatedDonation, message, this.state.newDonation);
        }
    }

    addError(message) {
        let currenterror = this.state.error ?? '';
        currenterror += message + "<br />";
        this.setState({ error: currenterror });
    }

    clearError() {
        this.setState({ error: null });
    }

    handleFormEditValue(val, propertyName) {
        let donationDto = Object.assign({}, this.state.donationDto);
        donationDto[propertyName] = val;
        this.setState({ donationDto: donationDto });
    }

    handleFormEdit(event, propertyName) {

        let donationDto = Object.assign({}, this.state.donationDto);
        donationDto[propertyName] = event.target.value;
        this.setState({ donationId: this.state.donationId, donation: this.state.donation, donationDto: donationDto, loading: this.state.loading, showModal: this.state.showModal });
    }

    setAnonymousDonor(isAnonymous) {
        let donationDto = Object.assign({}, this.state.donationDto);
        let wasOriginalAnonymous = this.state.donation?.donor?.externalIdentifier === this.AnonymousID;

        if (isAnonymous) {
            donationDto.donorExternalIdentifier = this.AnonymousID;
            donationDto.donorFirstName = 'Anonym';
            donationDto.donorLastName = 'Givare';
        }
        else {

            if (wasOriginalAnonymous) {
                donationDto.donorId = 0;
            }
            donationDto.donorExternalIdentifier = null;
            donationDto.donorFirstName = '';
            donationDto.donorLastName = '';
        }

        this.setState({ donationDto: donationDto });
    }

    updateRecipient(selectedContact) {
        let donationDto = Object.assign({}, this.state.donationDto);
        donationDto.recipient = selectedContact.recipient;
        donationDto.recipientAddress1 = selectedContact.address1;
        donationDto.recipientAddress2 = selectedContact.address2;
        donationDto.recipientZip = selectedContact.zip;
        donationDto.recipientCity = selectedContact.city;
        donationDto.recipientEmail = selectedContact.email;


        this.setState({ donationDto: donationDto });

    }

    getCreateDefaults() {
        let donationCreateDto = new DonationCreateDto();

        donationCreateDto.donationState = "3";
        donationCreateDto.dateCreatedUtc = TextConversionService.FormatDateTime(new Date());
        donationCreateDto.donationType = "2";
        donationCreateDto.paymentMethod = "";
        donationCreateDto.amount = 0;
        donationCreateDto.donorFirstName = '';
        donationCreateDto.donorLastName = '';
        donationCreateDto.donorAddress1 = '';
        donationCreateDto.donorAddress2 = '';
        donationCreateDto.donorZip = '';
        donationCreateDto.donorCity = '';
        donationCreateDto.donorEmail = '';
        donationCreateDto.isCompany = false;
        donationCreateDto.donorCompanyName = '';
        donationCreateDto.donorIdentificationNumber = '';

        donationCreateDto.recipient = '';
        donationCreateDto.recipientAddress1 = '';
        donationCreateDto.recipientAddress2 = '';
        donationCreateDto.recipientZip = '';
        donationCreateDto.recipientCity = '';
        donationCreateDto.recipientEmail = '';

        donationCreateDto.honoraryFirstName = '';
        donationCreateDto.honoraryLastName = '';
        donationCreateDto.honoraryMessage = '';
        donationCreateDto.honoraryMessageFrom = '';
        donationCreateDto.honoraryDateUtc = '';
        donationCreateDto.imageType = 'standard';
        donationCreateDto.comments = '';
        donationCreateDto.donationDelivery = 1;

        return donationCreateDto;
    }

    convertDonationToDto(donation) {
        let donationDto = new DonationDto();
        donationDto.id = donation.id;
        donationDto.donorId = donation.donor.id;
        donationDto.donorFirstName = donation.donor.firstName ?? '';
        donationDto.donorLastName = donation.donor.lastName;
        donationDto.donorAddressId = donation.donor.address.id;
        donationDto.donorAddress1 = donation.donor.address.address1 ?? '';
        donationDto.donorAddress2 = donation.donor.address.address2 ?? '';
        donationDto.donorZip = donation.donor.address.zip ?? '';
        donationDto.donorCity = donation.donor.address.city ?? '';
        donationDto.donorEmail = donation.donor.address.email ?? '';
        donationDto.isCompany = donation.donor.isCompany;
        donationDto.donorCompanyName = donation.donor.companyName;
        donationDto.donorIdentificationNumber = donation.donor.identificationNumber;
        donationDto.donorExternalIdentifier = donation.donor.externalIdentifier;

        donationDto.recipient = donation.recipient ?? '';
        donationDto.recipientAddressId = donation.recipientAddress.id;
        donationDto.recipientAddress1 = donation.recipientAddress.address1 ?? '';
        donationDto.recipientAddress2 = donation.recipientAddress.address2 ?? '';
        donationDto.recipientZip = donation.recipientAddress.zip ?? '';
        donationDto.recipientCity = donation.recipientAddress.city ?? '';
        donationDto.recipientEmail = donation.recipientAddress.email ?? '';

        donationDto.honoraryFirstName = donation.honoraryFirstName ?? '';
        donationDto.honoraryLastName = donation.honoraryLastName ?? '';
        donationDto.honoraryMessage = donation.honoraryMessage ?? '';
        donationDto.honoraryMessageFrom = donation.honoraryMessageFrom ?? '';
        donationDto.honoraryDateUtc = donation.honoraryDateUtc ? TextConversionService.FormatDate(new Date(donation.honoraryDateUtc)) : '';
        donationDto.comments = donation.comments ?? '';
        donationDto.imageType = donation.imageType ?? 'standard';
        donationDto.imageReference = donation.imageReference ?? null;
        donationDto.donationDelivery = donation.donationDelivery ?? 1;

        return donationDto;
    }

    showClearSent(event) {
        this.setState({ editSent: true })
    }

    handleShouldClearSent() {
        this.shouldClearSent = !this.shouldClearSent;
        console.log(this.shouldClearSent);
    }

    async loadSparAdress() {
        if (!this.state.donationDto.donorIdentificationNumber) {
            return;
        }

        let url = new URL(`api/donationedit/realaddress`, document.baseURI);
        const response = await fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            body: JSON.stringify({ ssn: this.state.donationDto.donorIdentificationNumber }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            }
        }).catch(error => {
            this.setState({ addressError: 'Ingen adress hittades' });
        });

        if (response.ok) {
            const data = await response.json();
            if (data && data.address) {
                let donationDto = Object.assign({}, this.state.donationDto);
                donationDto.donorFirstName = data.address.firstname;
                donationDto.donorLastName = data.address.lastname;
                donationDto.donorAddress1 = data.address.address2;
                donationDto.donorAddress2 = data.address.address1;
                donationDto.donorZip = data.address.postalcode;
                donationDto.donorCity = data.address.city;
                this.setState({ donationDto: donationDto, addressError: '' });
            }
        } else {
            this.setState({ addressError: 'Ingen adress hittades' });
        }
    }

    async loadMemoryImages() {
        let url = new URL(`api/image/memoryimages`, document.baseURI);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            }
        }).catch(error => console.log("Error loading memory images", error));

        if (response.ok) {
            const data = await response.json();
            this.setState({ memoryImages: data });
        }
    }

    async loadMemoryVerses() {
        let url = new URL(`api/donationedit/memoryverses`, document.baseURI);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            }
        }).catch(error => console.log("Error loading memory verses", error));
        if (response.ok) {
            const data = await response.json();
            this.setState({ memoryVerses: data });
        }
    }

    async loadCelebrationVerses() {
        let url = new URL(`api/donationedit/celebrationverses`, document.baseURI);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            }
        }).catch(error => console.log("Error loading celebration verses", error));

        if (response.ok) {
            const data = await response.json();
            this.setState({ celebrationVerses: data });
        }
    }


    async loadCelebrationImages() {
        let url = new URL(`api/image/celebrationimages`, document.baseURI);
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            }
        }).catch(error => console.log("Error loading celebration images", error));

        if (response.ok) {
            const data = await response.json();
            this.setState({ celebrationImages: data });
        }
    }

    async loadFullDonation() {
        if (typeof (this.state.donationId) !== 'undefined') {
            let url = new URL(`api/donationedit/${this.state.donationId}`, document.baseURI);
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getAccessToken(this.context)}`
                }
            });
            const data = await response.json();
            this.setState({ donationId: this.state.donationId, donation: response.ok ? data : null, donationDto: response.ok ? this.convertDonationToDto(data) : null, loading: false, showModal: this.state.showModal });

        }
    }

    async saveDonationDto() {
        this.clearError();
        if (typeof (this.state.donationDto !== 'undefined')) {
            if (this.state.newDonation) {
                if (ValidationService.HasInvalidValue(this.state.donationDto.amount, 0)) {
                    this.addError(`Ogiltigt belopp ${this.state.donationDto.amount}`);
                    return;
                }

                if(!this.state.donationDto.paymentMethod) {
                    this.addError(`Välj betalsätt för gåvan`);
                    return;
                }

                if (this.state.donationDto.paymentMethod === 'invoice' && !this.state.donationDto.isCompany) {
                    if (this.state.donationDto.donorExternalIdentifier === this.AnonymousID) {
                        this.addError(`Givaren kan inte vara anonym när betalmedel är Inbetalningsavi`);
                        return;
                    }
                }

                if (this.state.donationDto.isCompany && ValidationService.HasInvalidValue(this.state.donationDto.donorCompanyName, '')) {
                    this.addError(`Ange företagsnamn när givaren är ett företag`);
                    return;
                }

                if (!this.state.donationDto.isCompany && ValidationService.HasInvalidValue(this.state.donationDto.donorFirstName, '') && ValidationService.HasInvalidValue(this.state.donationDto.donorLastName, '')) {
                    this.addError(`Ange namn på givaren`);
                    return;
                }

                if (this.state.donationDto.dateCreatedUtc) {
                    let createdate = new Date(this.state.donationDto.dateCreatedUtc);
                    if (createdate) {
                        this.state.donationDto.dateCreatedUtc = createdate.toISOString();
                    }
                }
            }

            this.state.donationDto.clearSent = this.shouldClearSent;

            let donationDtoToSend = Object.assign({}, this.state.donationDto);

            if (donationDtoToSend.honoraryDateUtc) {
                let newdate = new Date(donationDtoToSend.honoraryDateUtc);
                newdate.setHours(0);
                donationDtoToSend.honoraryDateUtc = newdate.toISOString();
            }

            let url = this.state.newDonation ? new URL(`api/donationedit`, document.baseURI) : new URL(`api/donationedit/${this.state.donationId}`, document.baseURI);

            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getAccessToken(this.context)}`
                },
                method: 'POST',
                body: JSON.stringify(donationDtoToSend)
            });
            if (response.ok) {
                let donationListItem = await response.json();
                this.handleModalClose(null, donationListItem, "Gåvan sparades");
            } else {

                this.addError("Ett fel uppstod när gåvan skulle sparas");
            }
        }

    }



    renderDonationForm() {
        let isAnonymous = this.state.donationDto.donorExternalIdentifier?.toString() === this.AnonymousID;
        return (
            <>
                <h3>Gåvonummer {this.state.donation?.externalReference ?? this.state.donation?.id}</h3>
                <div className='donationInfo'>
                    {
                        !this.state.newDonation &&
                        <div>
                            Gåvan skapad: {TextConversionService.FormatDate(Date.parse(this.state.donation?.dateCreatedUtc))}
                        </div>
                    }
                    <div>
                        Status: {TextConversionService.DonationStateName(this.state.donation?.donationState ?? "0")}
                    </div>
                    <div>
                        Skickad: {!this.state.editSent && (<>{this.state.donation?.sent ? TextConversionService.FormatDate(Date.parse(this.state.donation.dateSentUtc)) : "Ej skickad"} {this.state.donation?.sent ? <Button variant="outline-primary" onClick={this.showClearSent}><FontAwesomeIcon icon={faLock} /></Button> : ""} </>)}
                        {this.state.editSent && (<label><input type="checkbox" onChange={() => this.handleShouldClearSent()} ></input> Rensa skickat</label>)}
                    </div>
                    {!this.state.newDonation && <div>
                        Belopp: {this.state.donation?.amount} kr
                    </div>}
                </div>
                <Form className='donationEditForm'>
                    {
                        this.state.newDonation &&
                        <Row>
                            <Col md={3}>
                                <Form.Group controlId='dateCreatedUtc'>
                                    <Form.Label>Skapad</Form.Label>
                                    <Form.Control type="datetime-local" defaultValue={this.state.donationDto.dateCreatedUtc} onChange={(event) => this.handleFormEdit(event, 'dateCreatedUtc')} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId='amount'>
                                    <Form.Label>Belopp</Form.Label>
                                    <Form.Control type="number" value={this.state.donationDto.amount} onChange={(event) => this.handleFormEdit(event, 'amount')} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId='paymentMethod'>
                                    <Form.Label>Betalmedel</Form.Label>
                                    <Form.Select value={this.state.donationDto.paymentMethod} onChange={(event) => this.handleFormEdit(event, 'paymentMethod')} >
                                        <option>Välj betalsätt</option>
                                        <option value={'custom2'}>Bankgiro</option>
                                        <option value={'custom1'}>Plusgiro</option>
                                        <option value={'invoice'}>Inbetalningsavi</option>
                                        <option value={'card'}>Kort/Swish (Nets)</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>


                        </Row>
                    }
                    <Row>
                        <Col lg={6} md={12} sm={12} >
                            <h4>Givare</h4>
                            {(this.state.newDonation || this.state.donationDto.isCompany) &&
                                <Row>
                                    {this.state.newDonation &&
                                        <Form.Check style={{ marginLeft: '13px' }} id='isCompany' type="checkbox" label='Givaren är ett företag' defaultChecked={this.state.donationDto.isCompany} onChange={(event) => { this.handleFormEditValue(event.target.checked, 'isCompany'); }}></Form.Check>
                                    }
                                    {this.state.donationDto.isCompany &&
                                        <Form.Group controlId='donorCompanyName'>
                                            <Form.Label>Företagsnamn</Form.Label>
                                            <Form.Control value={this.state.donationDto.donorCompanyName} onChange={(event) => this.handleFormEdit(event, 'donorCompanyName')} />
                                        </Form.Group>
                                    }
                                </Row>}
                            <Row className="position-relative mb-0" >
                                <Col>
                                    <Form.Check id="anocheck" disabled={!this.state.newDonation} label='Givaren är anonym' defaultChecked={isAnonymous} type="checkbox" onClick={event => this.setAnonymousDonor(event.target.checked)} ></Form.Check>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId='donorFirstName'>
                                        <Form.Label>Förnamn</Form.Label>
                                        <Form.Control readOnly={isAnonymous} value={this.state.donationDto.donorFirstName} onChange={(event) => this.handleFormEdit(event, 'donorFirstName')} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId='donorLastName'>
                                        <Form.Label>Efternamn</Form.Label>
                                        <Form.Control readOnly={isAnonymous} value={this.state.donationDto.donorLastName} onChange={(event) => this.handleFormEdit(event, 'donorLastName')} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group controlId='donorAddress1'>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control value={this.state.donationDto.donorAddress1} onChange={(event) => this.handleFormEdit(event, 'donorAddress1')} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group controlId='donorAddress2'>
                                    <Form.Label>Address 2</Form.Label>
                                    <Form.Control value={this.state.donationDto.donorAddress2} onChange={(event) => this.handleFormEdit(event, 'donorAddress2')}  ></Form.Control>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId='donorZip'>
                                        <Form.Label>Postnr</Form.Label>
                                        <Form.Control value={this.state.donationDto.donorZip} onChange={(event) => this.handleFormEdit(event, 'donorZip')} ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={8}>
                                    <Form.Group controlId='donorCity'>
                                        <Form.Label>Stad</Form.Label>
                                        <Form.Control value={this.state.donationDto.donorCity} onChange={(event) => this.handleFormEdit(event, 'donorCity')}  ></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group controlId='donorEmail'>
                                    <Form.Label>E-post givare</Form.Label>
                                    <Form.Control value={this.state.donationDto.donorEmail} onChange={(event) => this.handleFormEdit(event, 'donorEmail')}  ></Form.Control>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group controlId='donorIdentificationNumber'>
                                    <Form.Label>Persnr/Orgnr</Form.Label>
                                    <InputGroup>
                                        <Form.Control value={this.state.donationDto.donorIdentificationNumber} onChange={(event) => this.handleFormEdit(event, 'donorIdentificationNumber')}  ></Form.Control>
                                        <Button disabled={isAnonymous} variant="outline-primary" id="btnSparAddress" onClick={async event => await this.loadSparAdress()}>
                                            <FontAwesomeIcon icon={faAddressCard} />
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                                {this.state.addressError && <div className="invalid-feedback d-block">{this.state.addressError}</div>}
                            </Row>
                        </Col>
                        <Col md={6} sm={12} >
                            <h4>Mottagare</h4>
                            <Row>
                                <Col>
                                    <Form.Group controlId='searchContact'>
                                        <Form.Label>Sök kontakter</Form.Label>
                                        <ContactSelect notifyParent={this.updateRecipient}></ContactSelect>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId='recipientName'>
                                        <Form.Label>Namn</Form.Label>
                                        <Form.Control value={this.state.donationDto.recipient || ''} onChange={(event) => this.handleFormEdit(event, 'recipient')} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group controlId='recipientAddress1'>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control value={this.state.donationDto.recipientAddress1 || ''} onChange={(event) => this.handleFormEdit(event, 'recipientAddress1')}  ></Form.Control>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group controlId='recipientAddress2'>
                                    <Form.Label>Address 2</Form.Label>
                                    <Form.Control value={this.state.donationDto.recipientAddress2 || ''} onChange={(event) => this.handleFormEdit(event, 'recipientAddress2')}  ></Form.Control>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId='recipientZip'>
                                        <Form.Label>Postnr</Form.Label>
                                        <Form.Control value={this.state.donationDto.recipientZip || ''} onChange={(event) => this.handleFormEdit(event, 'recipientZip')}   ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={8}>
                                    <Form.Group controlId='recipientCity'>
                                        <Form.Label>Stad</Form.Label>
                                        <Form.Control value={this.state.donationDto.recipientCity || ''} onChange={(event) => this.handleFormEdit(event, 'recipientCity')} ></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId='recipientEmail'>
                                        <Form.Label>E-post mottagare</Form.Label>
                                        <Form.Control value={this.state.donationDto.recipientEmail || ''} onChange={(event) => this.handleFormEdit(event, 'recipientEmail')} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Leveranssätt</Form.Label>
                                    <Form.Check type='radio' name="delivery" defaultChecked={this.state.donationDto.donationDelivery === 1} onChange={event => this.handleFormEditValue(1, 'donationDelivery')} id='rb-del-print' label='Utskrivet'></Form.Check>
                                    <Form.Check type='radio' name="delivery" defaultChecked={this.state.donationDto.donationDelivery === 2} onChange={event => this.handleFormEditValue(2, 'donationDelivery')} id='rb-del-digital' label='Digitalt'></Form.Check>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {
                        this.state.newDonation &&
                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Gåvotyp</Form.Label>
                                    <Form.Select value={this.state.donationDto.donationType} onChange={(event) => this.handleFormEdit(event, 'donationType')} >                                        
                                        <option value={2}>Engångsgåva</option>
                                        <option value={0}>Minnesgåva</option>
                                        <option value={1}>Gratulationsgåva</option>
                                        <option value={3}>Övrigt</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    }
                    {((this.state.newDonation && (this.state.donationDto.donationType === "1" || this.state.donationDto.donationType === "0")) || this.state.donation?.donationType === 0 || this.state.donation?.donationType === 1) &&
                        <Row>
                            <Col>
                                <h4>Information</h4>
                                <h5>Hedersperson</h5>
                                <Row>
                                    <Col md={3}>
                                        <Form.Group controlId='honoraryFirstName'>
                                            <Form.Label>Förnamn</Form.Label>
                                            <Form.Control value={this.state.donationDto.honoraryFirstName} onChange={(event) => this.handleFormEdit(event, 'honoraryFirstName')} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId='honoraryLastName'>
                                            <Form.Label>Efternamn</Form.Label>
                                            <Form.Control value={this.state.donationDto.honoraryLastName} onChange={(event) => this.handleFormEdit(event, 'honoraryLastName')} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group controlId='honoraryDateUtc'>
                                            <Form.Label>Händelsedatum</Form.Label>
                                            <Form.Control type="date" value={this.state.donationDto.honoraryDateUtc} onChange={(event) => this.handleFormEdit(event, 'honoraryDateUtc')} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {((this.state.newDonation && this.state.donationDto.donationType === "0") || this.state.donation?.donationType === 0) &&
                                    <Row>
                                        <Col md={6}>
                                            <Form.Select className="versecollection" onChange={(event) => this.handleFormEditValue(event.target.value, 'honoraryMessage')} >
                                                <option>Välj en vers</option>
                                                {this.state.memoryVerses.map((element, i) => <option key={i} value={element.text}>{element.text}</option>)}
                                            </Form.Select>
                                        </Col>
                                    </Row>

                                }

                                {((this.state.newDonation && this.state.donationDto.donationType === "1") || this.state.donation?.donationType === 1) &&
                                    <Row>
                                        <Col md={6}>
                                            <Form.Select className="versecollection" onChange={(event) => this.handleFormEditValue(event.target.value, 'honoraryMessage')} >
                                                <option>Välj en vers</option>
                                                {this.state.celebrationVerses.map((element, i) => <option key={i} value={element.text}>{element.text}</option>)}
                                            </Form.Select>
                                        </Col>
                                    </Row>

                                }
                                <Row>
                                    <Col>

                                        <Form.Group controlId='honoraryMessage'>
                                            <Form.Label>Hälsning</Form.Label>
                                            <Form.Control as="textarea" rows={3} value={this.state.donationDto.honoraryMessage} onChange={(event) => this.handleFormEdit(event, 'honoraryMessage')}  ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId='honoraryMessageFrom'>
                                            <Form.Label>Från</Form.Label>
                                            <Form.Control as="textarea" rows={3} value={this.state.donationDto.honoraryMessageFrom} onChange={(event) => this.handleFormEdit(event, 'honoraryMessageFrom')}  ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {((this.state.newDonation && this.state.donationDto.donationType === "0") || (this.state.donation?.donationType === 0 && this.state.donation?.imageType === 'standard')) &&
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="imageType">
                                                <Form.Label>Bild</Form.Label>
                                                <div>
                                                    {this.state.memoryImages.map((element, i) => (<img key={i} width={100} src={element.url} alt="" style={{ marginRight: '10px' }} className={this.state.donationDto.imageReference === `${element.id}${element.url.substr(element.url.lastIndexOf('.'))}` ? 'active' : ''} onClick={(event) => this.handleFormEditValue(`${element.id}${element.url.substr(element.url.lastIndexOf('.'))}`, 'imageReference')} />))}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                }
                                {((this.state.newDonation && this.state.donationDto.donationType === "1") || (this.state.donation?.donationType === 1 && this.state.donation?.imageType === 'standard')) &&
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="imageType">
                                                <Form.Label>Bild</Form.Label>
                                                <div>
                                                    {this.state.celebrationImages.map((element, i) => (<img key={i} width={100} src={element.url} alt="" style={{ marginRight: '10px' }} className={this.state.donationDto.imageReference === `${element.id}${element.url.substr(element.url.lastIndexOf('.'))}` ? 'active' : ''} onClick={(event) => this.handleFormEditValue(`${element.id}${element.url.substr(element.url.lastIndexOf('.'))}`, 'imageReference')} />))}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>}
                    <Row>
                        <Col>
                            <Form.Group controlId='comments'>
                                <Form.Label>Kommentarer</Form.Label>
                                <Form.Control as="textarea" rows={3} value={this.state.donationDto.comments} onChange={(event) => this.handleFormEdit(event, 'comments')}  ></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                </Form>
            </>
        )
    }

    render() {
        let contents = this.state.loading || (this.state.donation === null && !this.state.newDonation)
            ? <Spinner animation="border" variant="primary"><span className="visually-hidden">Laddar gåva...</span></Spinner> :
            this.renderDonationForm();


        return (
            <Modal show={this.state.showModal} backdrop="static" onHide={(event) => this.handleModalClose(event, null, null)} size="xl" >
                <Modal.Header closeButton>
                    <Modal.Title>Redigera gåva</Modal.Title>
                </Modal.Header>
                <Modal.Body>{contents}</Modal.Body>
                <Modal.Footer>
                    {this.state.error &&
                        <Alert variant="danger">
                            <div dangerouslySetInnerHTML={{ __html: this.state.error }}></div>
                        </Alert>
                    }
                    <Button variant="secondary" onClick={(event) => this.handleModalClose(event, null, null)}>
                        Avbryt
                    </Button>
                    <Button variant="primary" onClick={this.saveDonationDto}>
                        Spara
                    </Button>

                </Modal.Footer>
            </Modal >

        );
    }

}