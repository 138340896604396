import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './navigation/NavMenu';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { SignInButton } from './SignInButton';

export class Layout extends Component {
  static displayName = Layout.name;
 
  render() {
    const signInStyle = {
      position: 'relative',
      top: 'calc(50vh - 112px)'
    }
  

    return (
      <div>
        <NavMenu />
        <AuthenticatedTemplate>
          <Container fluid={true}>
            {this.props.children}
          </Container>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Container fluid={true}>
            <div className='row'>
              <div className='col text-center'>
              <SignInButton style={signInStyle} />
              </div>
            </div>
          </Container>
        </UnauthenticatedTemplate>
      </div>
    );
  }
}
