import { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

export class DonationPrint extends Component {
    printCallback = null;

    constructor(props) {
        super(props);        
        if (props.notifyParent) {
            this.printCallback = props.notifyParent;
        }
        this.state = { markAsSent: true, showModal: props.show, includeImages: false, printing: false };
        this.createPdf = this.createPdf.bind(this);
    }

    checkChangedHandler(event) {
        this.setState((prevState) => { return { markAsSent: !prevState.markAsSent } });
    }

    checkImagesChangedHandler(event) {
        this.setState((prevState) => { return { includeImages: !prevState.includeImages } });
    }

    handleModalClose(event) {
        if (typeof (this.printCallback) === 'function') {
            this.printCallback(event, false);
        }
    }

    createPdf(event) {
        if (typeof (this.printCallback) === 'function') {
            this.setState({ printing: true });
            this.printCallback(event, true, false, this.state.includeImages, this.state.markAsSent);
        }
    }

    render() {
        return (
            <Modal centered={true} show={this.state.showModal} onHide={(event) => this.handleModalClose(event)} size="md" >
                <Modal.Header closeButton>
                    <Modal.Title>Skriv ut gåvoblad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-check">
                        <input className='form-check-input' id='markAsSent' type='checkbox' name='markAsSent' value='markAsSent' disabled={this.state.printing} checked={this.state.markAsSent} onChange={(event) => this.checkChangedHandler(event)} />
                        <label className="form-check-label" htmlFor="markAsSent">
                            Markera gåvor som skickade
                        </label>
                    </div>
                    <div className="form-check">
                        <input className='form-check-input' id='includeImages' type='checkbox' name='includeImages' disabled={this.state.printing} value='includeImages' checked={this.state.includeImages} onChange={(event) => this.checkImagesChangedHandler(event)} />
                        <label className="form-check-label" htmlFor="includeImages">
                            Inkludera bilder i gåvoblad
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" disabled={this.state.printing} onClick={(event) => this.handleModalClose(event, null, null)}>
                        Avbryt
                    </Button>
                    <Button variant="primary" disabled={this.state.printing} onClick={this.createPdf}>
                        Skriv ut
                    </Button>
                    { this.state.printing && <Spinner animation="border" variant="primary" size="sm" ><span className="visually-hidden">Skapar dokument...</span></Spinner>}
                </Modal.Footer>
            </Modal >

        );
    }
}