import { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

export class DonationThankedStatus extends Component {
    setThankedStatusCallback = null;

    constructor(props) {
        super(props);
        console.log(props);
        if (props.notifyParent) {
            this.setThankedStatusCallback = props.notifyParent;
        }
        this.state = { thanked: true, showModal: props.show, settingThankedStatus: false };
        this.checkChangedHandler = this.checkChangedHandler.bind(this);
        this.updateThankedStatus = this.updateThankedStatus.bind(this);
    }

    checkChangedHandler(event) {
        this.setState((prevState) => { return { thanked: !prevState.thanked } });
    }

    handleModalClose(event) {
        if (typeof (this.setThankedStatusCallback) === 'function') {
            this.setThankedStatusCallback(event, false);
        }
    }

    updateThankedStatus(event) {
        if (typeof (this.setThankedStatusCallback) === 'function') {
            this.setState({ settingThankedStatus: true });
            this.setThankedStatusCallback(event, true, this.state.thanked);
        }
    }


    render() {
        return(
            <Modal centered={true} show={this.state.showModal} onHide={(event) => this.handleModalClose(event)} size="md" >
                <Modal.Header closeButton>
                    <Modal.Title>Ändra tackstatus</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-check">
                        <input className='form-check-input' id='thanked' type='checkbox' name='thanked' value='thanked' disabled={this.state.settingThankedStatus} checked={this.state.thanked} onChange={(event) => this.checkChangedHandler(event)} />
                        <label className="form-check-label" htmlFor="thanked">
                            Markera gåvor som tackade
                        </label>
                    </div>                   
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" disabled={this.state.settingThankedStatus} onClick={(event) => this.handleModalClose(event, null, null)}>
                        Avbryt
                    </Button>
                    <Button variant="primary" disabled={this.state.settingThankedStatus} onClick={this.updateThankedStatus}>
                        Spara
                    </Button>
                    { this.state.settingThankedStatus && <Spinner animation="border" variant="primary" size="sm" ><span className="visually-hidden">Uppdaterar tackstatus...</span></Spinner>}
                </Modal.Footer>
            </Modal >

        )
    }
}