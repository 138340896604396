import { loginRequest } from "../authConfig";
export async function getAccessToken(msalContext) {
    const { instance, accounts } = msalContext;   

    const request = {
        ...loginRequest,
        account: accounts[0]
    };
     // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    const silentResponse = await instance.acquireTokenSilent(request);
    
    if(silentResponse.accessToken) {
        return silentResponse.accessToken;
    }
    
    const popupResponse = await instance.acquireTokenPopup(request);
    
    if(popupResponse.accessToken) {
        return popupResponse.accessToken;
    }
     

    return "";    
}