import React, { Component } from 'react';
import { Link } from "react-router-dom";
import TextConversionService from '../../services/textConversionService';
import { MsalContext } from "@azure/msal-react";
import { getAccessToken } from "../../services/authService";


export class Statistics extends Component {
    static displayName = Statistics.name;
    static contextType = MsalContext;

    constructor(props) {
        super(props);
        this.state = this.getInitialState();    
        this.onHonorarySearchChange = this.onHonorarySearchChange.bind(this);
    }

    getInitialState() {
        const searchParams = new URLSearchParams(this.props.location.search);

        let honorarysearch = '';
        const honorarysearchParam = searchParams.get('honorarysearch');
        if (honorarysearchParam) {
            honorarysearch = honorarysearchParam;
        }

        const filters = {
            honorarysearch: honorarysearch
        }

        return { currentCount: 0, donations: [], loading: true, filters: filters };
    }


    onHonorarySearchChange(event) {
        if (event.keyCode === 13) {
            this.updateState('honorarysearch', event.target.value);
        }
    }  

    updateState(key, value) {
        let updatedFilters = Object.assign({}, this.state.filters);
        updatedFilters[key] = value;

        this.setState((prevState) => {
            return { donations: prevState.donations, loading: prevState.loading, filters: updatedFilters };
        });
    }

    componentDidMount() {
        this.loadDonations();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.queryChanged(prevState)) {
            this.loadDonations();
        }
    }

    queryChanged(prevState) {
        return this.state.filters.honorarysearch !== prevState.filters.honorarysearch;
    }


    static renderDonationsTable(donations) {

        return (
            <table className='table table-striped table-hover' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Hedersperson</th>
                        <th className='text-center'>Datum</th>
                        <th>Antal gåvor</th>
                        <th>Belopp</th>
                    </tr>
                </thead>
                <tbody>
                    {donations && donations.length > 0 && donations.map(donation =>
                        <tr key={TextConversionService.B64Encode(`${donation.honoraryFirstName}|${donation.honoraryLastName}|${donation.honoraryDateUtc}`)} className={donation.sent && parseInt(donation.donationState) === 3 ? 'table-success' : ''} >
                            <td><Link to={`/?hp=${TextConversionService.B64Encode(`${donation.honoraryFirstName}|${donation.honoraryLastName}`)}`}>{donation.honoraryName}</Link></td>
                            <td className='text-center no-wrap'>{donation.honoraryDateUtc ? TextConversionService.FormatDate(new Date(donation.honoraryDateUtc)) : ''}</td>
                            <td>{donation.nrOfGifts}</td>
                            <td>{donation.amount}</td>
                        </tr>
                    )}
                    {
                        (!donations || donations.length === 0) &&
                        <tr>
                            <td colSpan='14' className='text-center'>Inga hederspersoner hittades</td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }


    render() {

        let contents = this.state.loading
            ? <p></p>
            : Statistics.renderDonationsTable(this.state.donations);

        return (            
            <div>
                <h1>Statistik</h1>
                <div className='col-auto col-2'>
                    <label htmlFor='honorarysearch'>Sök efter hedersperson</label>
                    <input type='text' name='honorarysearch' defaultValue={this.state.filters.honorarysearch} onKeyDown={this.onHonorarySearchChange} className='form-control' id='honorarysearch' placeholder='Ange namn på hedersperson'></input>
                </div>
                <div className='col-auto col-6'>
                    {contents}
                </div>
            </div>
        );
    }

    async loadDonations() {        
        let url = new URL('api/donationstatistics', document.baseURI);
        let params = Object.assign({}, this.state.filters);
        url.search = new URLSearchParams(params).toString();
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',               
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            }
        });
        const data = await response.json();       

        this.setState({ donations: response.ok ? data : [], loading: false, filters: params });
    }
}