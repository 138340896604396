import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { DonationRegister } from './components/donationregister/DonationRegister';
import { Contacts } from './components/contacts/Contacts';
import { Statistics } from './components/statistics/Statistics';
import { Settings } from './components/Settings';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={DonationRegister} />
        <Route path='/statistik' component={Statistics} />
        <Route path='/kontakter' component={Contacts} />
        <Route path='/installningar' component={Settings} />
      </Layout>
    );
  }
}
