
const ExportService = {
    DownloadFile: async function (selectedDonations, accessToken = null) {
        let url = new URL('api/donationexport/export', document.baseURI);
        var data = {
            "donationIds": selectedDonations
        };

        await this.PostData(url, '.xlsx', data, accessToken);
    },
    CreatePdf: async function (selectedDonations, isEnvelope = false, showPictures = false, markAsSent = false, accessToken = null) {
        let url = new URL('api/pdf/createpdf', document.baseURI);

        var data = {
            "donationIds": selectedDonations,
            "isEnvelope": isEnvelope,
            "showPictures": showPictures,
            "markAsSent": markAsSent
        };


        await this.PostData(url, '.pdf', data, accessToken);
    },
    EmailDonations: async function (selectedDonations, recipient, markAsSent, accessToken = null) {

        let url = new URL('api/email/sendemail', document.baseURI);

        var data = {
            "donationIds": selectedDonations,
            "recipient": recipient,
            "markAsSent": markAsSent
        };

        return await fetch(url,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                referrerPolicy:
                    'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });

    },
    PostData: async function (url = '', extension = '', data = {}, accessToken = null) {
        // Default options are marked with *
        const headers = {
            'Content-Type': 'application/json'
        }
        if (accessToken) {
            headers["Authorization"] = `Bearer ${accessToken}`;
        }

        await fetch(url,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: headers,
                redirect: 'follow', // manual, *follow, error
                referrerPolicy:
                    'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(function (response) { return response.json(); })
            .then(function (json) {
                var u = 'api/download/?fileId=' + json.fileId;
                let url = new URL(u, document.baseURI);

                const headersdl = {};
                if (accessToken) {
                    headersdl["Authorization"] = `Bearer ${accessToken}`;
                }

                fetch(url, {
                    headers: headersdl
                })
                    .then(function (response) {
                        var json = response.json();
                        return json;
                    })
                    .then(function (json) {

                        var bin = window.atob(json.data);
                        var bytes = [];
                        for (var i = 0; i < bin.length; i++) {
                            bytes[i] = bin.charCodeAt(i);
                        }
                        var byteArr = new Uint8Array(bytes);
                        var blob = new Blob([byteArr], { type: 'application/pdf' });
                        var url = URL.createObjectURL(blob);

                        var link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", json.filename + extension);

                        document.body.appendChild(link);
                        link.click();
                    });
            });
    }
}

export default ExportService;