const TextConversionService = {
    DonationTypeName: function (donationType) {
        switch (parseInt(donationType)) {
            case 0:
                return 'Minnesgåva';
            case 1:
                return 'Gratulationsgåva';
            case 2:
                return 'Engångsgåva';
            default:
                return 'Okänd gåvotyp';

        }
    },
    DonationStateName: function (donationState) {
        switch (parseInt(donationState)) {
            case 0:
                return 'Ny';
            case 1:
                return 'Bekräftad';
            case 2:
                return 'Behandlas';
            case 3:
                return 'Klart';
            case 4:
                return 'Avbruten';
            case 5:
                return 'Retur';
            case 6:
                return 'Manuell hantering';
            case 7:
                return 'Stängd (admin)';
            case 10:
                return 'Väntar bekräftelse';
            case 99:
                return 'Ogiltig status';
            default:
                return 'Okänd status';
        }
    },
    PaymentType: function (paymentType, isCompany) {
        if (paymentType) {
            switch (paymentType.toString().toLowerCase()) {
                case "b2c_se netseasycheckout":
                case "card":
                    return "Kort/Swish (Nets)"
                case "invoice":
                    return  isCompany ? "Faktura" : "Inbetalningsavi";
                case "custom2":
                    return "Bankgiro";
                case "custom1":
                    return "Plusgiro";
                case "se direct_swish":
                    return "Swish";
                case "se direct_swedbank_se":
                    return "Internetbank Swedbank";
                case "se direct_shb":
                    return "Internetbank Handelsbanken";
                case "se direct_nb":
                    return "Internetbank Nordea";
                case "se cc_cekab":
                    return "Kort (Visa/Mastercard)";
                case "se direct_sebp":
                    return "Internetbank SEB";
                default:
                    return paymentType;
            }
        }
        return paymentType;

    },
    FormatDate: function (date) {
        if (!date) {
            return date;
        }
        return new Intl.DateTimeFormat("sv-SE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }).format(date);
    },
    FormatDateTime: function (date) {
        if (!date) {
            return date;
        }
        return new Intl.DateTimeFormat("sv-SE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit"
        }).format(date);
    },

    FormatForWeb: function (input, findWhat, replaceWith) {
        if (input === null) {
            return '';
        }
        return input.replace(findWhat, replaceWith);
    },
    DisplayErrorFromResponse: async function (response) {
        let data = await response.json();

        let errorMessage = "";

        if (data.errors) {
            errorMessage = data.errors[Object.keys(data.errors)[0]][0];
        } else {
            errorMessage = data[Object.keys(data)[0]][0];
        }

        return errorMessage;
    },
    B64Encode: function (input) {
        return btoa(unescape(encodeURIComponent(input)));
    }

}

export default TextConversionService;