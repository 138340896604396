import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { MsalContext } from "@azure/msal-react";
import { getAccessToken } from "../../services/authService";


export default class PdfTemplatesSettings extends Component {
  static contextType = MsalContext;

  constructor(props) {
    super(props);
    this.state = { pdfTemplatesSettings: null, loading: true, editmode: false };
    this.editPdfTemplates = this.editPdfTemplates.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.savePdfTemplatesSettings = this.savePdfTemplatesSettings.bind(this);
  }

  componentDidMount() {
    this.loadPdfTemplatesSettings();
  }

  async loadPdfTemplatesSettings() {
    let url = new URL(`api/pdftemplatessettings`, document.baseURI);
    const response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${await getAccessToken(this.context)}`
      }
    });
    const data = await response.json();
    this.setState({ pdfTemplatesSettings: data, loading: false });
  }

  editPdfTemplates(event) {
    let clone = Object.assign({}, this.state.pdfTemplatesSettings);
    clone.pdfTemplates = Object.assign({}, this.state.pdfTemplatesSettings.pdfTemplates);
    this.setState({ editmode: true, pdfTemplatesSettingsClone: clone });
  }

  cancelEdit(event) {
    this.setState({ editmode: false, pdfTemplatesSettingsClone: {} });
  }

  templateUrlChanged(event, templateType) {
    let pdfTemplatesSettingsClone = Object.assign({}, this.state.pdfTemplatesSettingsClone);
    pdfTemplatesSettingsClone.pdfTemplates = Object.assign({}, this.state.pdfTemplatesSettingsClone.pdfTemplates);
    if (templateType === 'BaseUrl') {
      pdfTemplatesSettingsClone.baseUrl = event.target.value;
    }
    else if (templateType === 'Email') {
      pdfTemplatesSettingsClone.donationEmailTemplate = event.target.value;
    }
    else if (templateType === 'OrderConfirmationEmail') {
      pdfTemplatesSettingsClone.orderConfirmationEmailTemplate = event.target.value;
    }
    else if (templateType === 'DonationViewUrl') {
      pdfTemplatesSettingsClone.donationViewUrl = event.target.value;
    }
    else {
      pdfTemplatesSettingsClone.pdfTemplates[templateType] = event.target.value;
    }

    this.setState({ pdfTemplatesSettingsClone: pdfTemplatesSettingsClone });

  }

  async savePdfTemplatesSettings(event) {
    event.preventDefault();
    let pdfTemplatesSettings = Object.assign({}, this.state.pdfTemplatesSettingsClone);
    pdfTemplatesSettings.pdfTemplates = Object.assign({}, this.state.pdfTemplatesSettingsClone.pdfTemplates);

    let url = new URL(`api/pdftemplatessettings`, document.baseURI);
    const response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await getAccessToken(this.context)}`
      },
      method: "PUT",
      body: JSON.stringify(pdfTemplatesSettings)
    });
    if (response.ok) {
      let data = await response.json();
      pdfTemplatesSettings.id = data.id;
      this.setState({ editmode: false, pdfTemplatesSettings: pdfTemplatesSettings, pdfTemplatesSettingsClone: null });
      if (this.props.notify) {
        this.props.notify("Mallinställningar sparade", "Sparat");
      }

    } else {
      if (this.props.notify) {
        this.props.notify("Gick inte att spara mallinställningar", "Fel");
      }
    }
  }

  render() {
    let loading = this.state.loading || this.state.pdfTemplatesSettings === null;
    let editing = this.state.editmode;

    return (
      <>
        <h2>Mallar {!loading && !editing && <button type='button' className='btn btn-outline-primary' onClick={this.editPdfTemplates}><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></button>}</h2>
        {loading && <Spinner animation="border" variant="primary"><span className="visually-hidden">Laddar gåva...</span></Spinner>}
        {!loading && !editing && this.state.pdfTemplatesSettings !== null && (
          <div className='row'>
            <div className='col-lg-12 col-xl-8'>
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: '200px' }}>Malltyp</th>
                    <th>URL till mall</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>Basadress:</td><td>{this.state.pdfTemplatesSettings.baseUrl}</td></tr>
                  <tr><td>Minnesgåva:</td><td>{this.state.pdfTemplatesSettings.pdfTemplates.Memory}</td></tr>
                  <tr><td>Gratulationsgåva:</td><td> {this.state.pdfTemplatesSettings.pdfTemplates.Celebration}</td></tr>
                  <tr><td>Engångsgåva:</td><td>{this.state.pdfTemplatesSettings.pdfTemplates.OneTime} </td></tr>
                  <tr><td>Försättsblad:</td><td>{this.state.pdfTemplatesSettings.pdfTemplates.Unknown}</td></tr>
                  <tr><td>E-postmall digital leverans:</td><td>{this.state.pdfTemplatesSettings.donationEmailTemplate}</td></tr>
                  <tr><td>E-postmall orderbekräftelse:</td><td>{this.state.pdfTemplatesSettings.orderConfirmationEmailTemplate}</td></tr>
                  <tr><td>Url till gåvobrevslänk:</td><td>{this.state.pdfTemplatesSettings.donationViewUrl}</td></tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {!loading && editing && this.state.pdfTemplatesSettings !== null && (
          <div className='row'>
            <div className='col-lg-12 col-xl-8'>
              <form onSubmit={this.savePdfTemplatesSettings}>
                <div className="row g-3 mb-3 mt-3">
                  <div className="col-lg-12 col-xl-2">
                    <label htmlFor="txtBaseUrl" className="col-form-label">Basadress</label>
                  </div>
                  <div className="col-lg-12 col-xl-8">
                    <input type="url" id="txtBaseUrl" value={this.state.pdfTemplatesSettingsClone.baseUrl} onChange={(event) => this.templateUrlChanged(event, 'BaseUrl')} className="form-control" required placeholder='Ange url till basadress för mallinnehåll' />
                  </div>
                </div>
                <div className="row g-3 mb-3 mt-3">
                  <div className="col-lg-12 col-xl-2">
                    <label htmlFor="txtMemoryGiftUrl" className="col-form-label">Minnesgåva</label>
                  </div>
                  <div className="col-lg-12 col-xl-8">
                    <input type="url" id="txtMemoryGiftUrl" value={this.state.pdfTemplatesSettingsClone.pdfTemplates.Memory} onChange={(event) => this.templateUrlChanged(event, 'Memory')} className="form-control" required placeholder='Ange url till mall för Minnesgåva' />
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col-lg-12 col-xl-2">
                    <label htmlFor="txtCelebrationGiftUrl" className="col-form-label">Gratulationsgåva</label>
                  </div>
                  <div className="col-lg-12 col-xl-8">
                    <input type="url" id="txtCelebrationGiftUrl" value={this.state.pdfTemplatesSettingsClone.pdfTemplates.Celebration} onChange={(event) => this.templateUrlChanged(event, 'Celebration')} className="form-control" required placeholder='Ange url till mall för Gratulationsgåva' />
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col-lg-12 col-xl-2">
                    <label htmlFor="txtOneTimeGiftUrl" className="col-form-label">Engångsgåva</label>
                  </div>
                  <div className="col-lg-12 col-xl-8">
                    <input type="url" id="txtOneTimeGiftUrl" value={this.state.pdfTemplatesSettingsClone.pdfTemplates.OneTime} onChange={(event) => this.templateUrlChanged(event, 'OneTime')} className="form-control" required placeholder='Ange url till mall för Engångsgåva' />
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col-lg-12 col-xl-2">
                    <label htmlFor="txtUnkownGiftUrl" className="col-form-label">Försättsblad</label>
                  </div>
                  <div className="col-lg-12 col-xl-8">
                    <input type="url" id="txtUnkownGiftUrl" value={this.state.pdfTemplatesSettingsClone.pdfTemplates.Unknown} onChange={(event) => this.templateUrlChanged(event, 'Unknown')} className="form-control" required placeholder='Ange url till mall för Försättsblad' />
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col-lg-12 col-xl-2">
                    <label htmlFor="txtEmailTemplateUrl" className="col-form-label">E-postmall digital leverans</label>
                  </div>
                  <div className="col-lg-12 col-xl-8">
                    <input type="url" id="txtEmailTemplateUrl" value={this.state.pdfTemplatesSettingsClone.donationEmailTemplate} onChange={(event) => this.templateUrlChanged(event, 'Email')} className="form-control" required placeholder='Ange url till mall för E-postmall för digital leverans' />
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col-lg-12 col-xl-2">
                    <label htmlFor="txtOrderConfirmationEmailTemplateUrl" className="col-form-label">E-postmall orderbekräfelse</label>
                  </div>
                  <div className="col-lg-12 col-xl-8">
                    <input type="url" id="txtOrderConfirmationEmailTemplateUrl" value={this.state.pdfTemplatesSettingsClone.orderConfirmationEmailTemplate} onChange={(event) => this.templateUrlChanged(event, 'OrderConfirmationEmail')} className="form-control" required placeholder='Ange url till mall för E-postmall för orderbekräftelse' />
                  </div>
                </div>
                <div className="row g-3  mb-3">
                  <div className="col-lg-12 col-xl-2">
                    <label htmlFor="txtEmailTemplateUrl" className="col-form-label">Url till gåvobrevslänk</label>
                  </div>
                  <div className="col-lg-12 col-xl-8">
                    <input type="url" id="txtEmailTemplateUrl" value={this.state.pdfTemplatesSettingsClone.donationViewUrl} onChange={(event) => this.templateUrlChanged(event, 'DonationViewUrl')} className="form-control" required placeholder='Ange url till granskning av gåvobrev (länk till e-postmallar)' />
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-auto">
                    <button type='button' className='btn btn-secondary' onClick={this.cancelEdit}>Avbryt</button>
                    <button type='submit' className='btn btn-primary mx-3'>Spara</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </>
    )
  }
}

