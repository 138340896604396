import { Component } from "react";
import { Pagination } from "react-bootstrap";

export class CustomPagination extends Component {
    gotoPageCallback = null;
    constructor(props) {
        super(props);       
        this.state = { currentPage: parseInt(this.props.currentPage), pageSize: parseInt(this.props.pageSize), totalFound: this.props.totalFound, totalPages: Math.ceil(this.props.totalFound / this.props.pageSize) };
        if(this.props.gotoPageCallback) {
            this.gotoPageCallback = this.props.gotoPageCallback;
        }

    }

    componentDidUpdate() {        
        if( parseInt(this.props.currentPage) !== this.state.currentPage || parseInt(this.props.pageSize) !== this.state.pageSize || this.state.totalPages !==   Math.ceil(this.props.totalFound / this.props.pageSize)) {            
            this.setState({ currentPage: parseInt(this.props.currentPage), pageSize: parseInt(this.props.pageSize), totalFound: this.props.totalFound, totalPages: Math.ceil(this.props.totalFound / this.props.pageSize) });
        }
    }

    gotoPage(event, i) {        
        if(typeof(this.gotoPageCallback) === 'function') {
            this.gotoPageCallback(i);
        }

    }


    render() {
        let currentPage = this.state.currentPage;
        let minpage = Math.max(currentPage - 2, 1);
        let maxpage = Math.min(this.state.totalPages, currentPage + 2);
        let items = []
        for (let i = minpage; i <= maxpage; i++) {
            items.push(<Pagination.Item key={i} active={currentPage === i} onClick={(event) => this.gotoPage(event, i)} >{i}</Pagination.Item>)
        }

        return (
            <Pagination className='justify-content-center'>
                {items}
            </Pagination>
        )
    }
}