import { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ValidationService from "../../services/validationService";

export class DonationSend extends Component {
    sendCallback = null;
    constructor(props) {
        super(props);
        if (props.notifyParent) {
            this.sendCallback = props.notifyParent;
        }
        this.state = { markAsSent: true, showModal: props.show, sending: false };
        this.send = this.send.bind(this);
        this.onRecipientAddressChange = this.onRecipientAddressChange.bind(this);
        this.onRecipientAddressBlur = this.onRecipientAddressBlur.bind(this);
    }

    onRecipientAddressChange(event) {
        this.setState({recipientAddress: event.target.value, emailError: false });
    }

    onRecipientAddressBlur(event) {      
        this.setState({recipientAddress: event.target.value, emailError: false });
    }

    checkChangedHandler(event) {
        this.setState((prevState) => { return { markAsSent: !prevState.markAsSent } });
    }

    checkImagesChangedHandler(event) {
        this.setState((prevState) => { return { includeImages: !prevState.includeImages } });
    }

    handleModalClose(event) {
        if (typeof (this.sendCallback) === 'function') {
            this.sendCallback(event, false);
        }
    }

    send(event) {        
        if(this.state.recipientAddress && this.state.recipientAddress !== ''){
            const emailValid = ValidationService.ValidEmail(this.state.recipientAddress);        
            if(!emailValid) {
                this.setState({ emailError: true});
                return;
            }
        }


        if (typeof (this.sendCallback) === 'function') {
            this.setState({ sending: true, emailError: false });
            this.sendCallback(event, true, this.state.recipientAddress, this.state.markAsSent);
        }
    }



    render() {
        return (
            <Modal centered={true} show={this.state.showModal} onHide={(event) => this.handleModalClose(event)} size="md" >
                <Modal.Header closeButton>
                    <Modal.Title>Skicka gåvoblad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-3'>
                        <label htmlFor="donationRecipientAddress" className="form-label">E-post mottagare</label>
                        <input type="email" className="form-control" disabled={this.state.sending} id="donationRecipientAddress" autoComplete="off" placeholder="namn@mottagare.se" defaultValue={this.state.recipientAddress} onBlur={this.onRecipientAddressBlur}  onKeyDown={this.onRecipientAddressChange} />
                        {this.state.emailError && <div className="invalid-feedback d-block">{this.state.recipientAddress} är ingen giltig e-postadress</div>}
                    </div>
                    <div className="form-check">
                        <input className='form-check-input' id='markAsSent' type='checkbox' name='markAsSent' value='markAsSent' disabled={this.state.sending} checked={this.state.markAsSent} onChange={(event) => this.checkChangedHandler(event)} />
                        <label className="form-check-label" htmlFor="markAsSent">
                            Markera gåvor som skickade
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" disabled={this.state.sending} onClick={(event) => this.handleModalClose(event)}>
                        Avbryt
                    </Button>
                    <Button variant="primary" disabled={this.state.sending} onClick={this.send}>
                       Skicka
                    </Button>
                    {this.state.sending && <Spinner animation="border" variant="primary" size="sm" ><span className="visually-hidden">Skickar dokument...</span></Spinner>}
                </Modal.Footer>
            </Modal >
        )
    }
}
