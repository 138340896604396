import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

export class Toaster extends Component {
    state = {message: "", errorMessage: "", headline: ""};

    addMessage = (message, headline) => {
        this.setState({ message, headline });
    }

    addError = (message, headline) => {
        this.setState({ errorMessage: message, headline });
    }

    clearToast = (event) => {
        this.setState({ message: null, errorMessage: null, headline: null });
      }
    

    renderToast(message, header, error = false) {
        let css = error ? 'danger' : 'success';
        let icon = error ? faExclamationCircle : faInfoCircle;
        return (
            <Toast onClose={this.clearToast} delay={3000} autohide bg={css} >
                <Toast.Header>
                    <FontAwesomeIcon icon={icon} className={error ? 'text-danger' : 'text-success'}></FontAwesomeIcon>
                    {header && <strong className="ps-2 me-auto">{header}</strong>}
                </Toast.Header>
                <Toast.Body className='text-white'>{message}</Toast.Body>
            </Toast>
        );
    }

    render() {
        return (
            <ToastContainer className="p-2 fixed-top mx-auto">
                {this.state.message && this.renderToast(this.state.message, this.state.headline)}
                {this.state.errorMessage && this.renderToast(this.state.errorMessage, this.state.headline, true)}
            </ToastContainer>
        )
    }
}