import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = (props) => {
    const { instance } = useMsal();

    return (
        <Button variant="primary" style={props.style} className="ml-auto" size='lg' onClick={() => handleLogin(instance)}><FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon> Logga in</Button>
    );
}
