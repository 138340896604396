import { Component } from "react";
import AsyncSelect from 'react-select/async';
import { MsalContext } from "@azure/msal-react";
import { getAccessToken } from "../../services/authService";

export class ContactSelect extends Component {
    static contextType = MsalContext;
    static searchUrl = new URL('api/contacts/autocomplete', document.baseURI);

    notifyParent = null;

    constructor(props) {
        super(props);        
        this.state = {
            selectedOption: null,
          };
        this.loadOptions = this.loadOptions.bind(this);
        this.getData = this.getData.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        if(this.props.notifyParent) {
            this.notifyParent = this.props.notifyParent;
        }
    }

    async getData(inputString) {        
        let response = await fetch(`${ContactSelect.searchUrl}?q=${encodeURIComponent(inputString)}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            },
            method: 'GET'
        });

        let data = await response.json();

        let result = data.items.map(c => {
            c["value"] = c.id.toString();
            c["label"] = `${c.recipient}, ${c.address1}, ${c.zip} ${c.city} `;
            return c;
        });               
        return result;
    }

    async loadOptions(inputString) {
        let self = this;        
        return new Promise(async resolve => {
            resolve(await self.getData(inputString));
        });
    }   

    onSelectChange(selectedOption) {      
        this.setState({selectedOption});
        if(typeof(this.notifyParent) === 'function') {
            this.notifyParent(selectedOption);
        }
    }

    render() {
        return (
            <AsyncSelect value={this.state.selectedOption} placeholder='Sök kontakter' cacheOptions loadOptions={this.loadOptions} onChange={this.onSelectChange} ></AsyncSelect>
        )
    }
}