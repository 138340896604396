import { Component } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { MsalContext } from "@azure/msal-react";
import { getAccessToken } from "../../services/authService";
import TextConversionService from "../../services/textConversionService";

export class ContactDelete extends Component {
    static contextType = MsalContext;
    parentModalCloseHandler = null;

    constructor(props) {
        super(props);
        this.state = { showModal: props.show, deleteContactId: props.deleteContactId, loading: false };
        if (props.notifyParent) {
            this.parentModalCloseHandler = props.notifyParent;
        }
    }

    handleModalClose(event, contactDeleted, message) {
        if (typeof (this.parentModalCloseHandler) === 'function') {
            this.parentModalCloseHandler(contactDeleted, message);
        }

        this.setState({ deleteContactId: null, loading: false, showModal: false });
    }

    toggleModal(show) {
        this.setState({ showModal: show });
    }

    setupDelete(deleteContactId, recipient) {
        this.setState({ deleteContactId, recipient });
    }

    deleteContact = async (event) => {
        if (typeof (this.state.deleteContactId !== 'undefined')) {
            let url = new URL(`api/contacts/${this.state.deleteContactId}`, document.baseURI);
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getAccessToken(this.context)}`
                },
                method: 'DELETE'
            });
            if (response.ok) {
                this.handleModalClose(null, true, "Kontakten raderades");
            }
            else {
                let errorMessages = await TextConversionService.DisplayErrorFromResponse(response);
                this.setState({ error: errorMessages });
            }
        }
    }


    render() {
        let contents = `Är du säker att du vill radera kontakten ${this.state.recipient}?`

        return (
            <Modal show={this.state.showModal} onHide={(event) => this.handleModalClose(event, false, null)} >
                <Modal.Header closeButton>
                    <Modal.Title>Ta bort kontakt</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {contents}
                    {this.state.error && <Alert variant="danger" >{this.state.error}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(event) => this.handleModalClose(event, false, null)}>
                        Avbryt
                    </Button>
                    <Button variant="primary" onClick={this.deleteContact}>
                        Radera
                    </Button>
                </Modal.Footer>
            </Modal >
        );
    }

}