import { Component } from "react";
import { MsalContext } from "@azure/msal-react";
import { getAccessToken } from "../../services/authService";

export default class IndexStatus extends Component {
    static contextType = MsalContext;


    constructor(props) {
        super(props);
        this.state = { dbcount: null, indexcount: null, loading: false };      
    }


    componentDidMount() {
        this.loadDonationIndexStatus();
    }

    async loadDonationIndexStatus() {
        this.setState({loading: true});
        let url = new URL(`api/donationstatistics/getdonationindexstatus`, document.baseURI);
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',               
                'Authorization': `Bearer ${await getAccessToken(this.context)}`
            }
        });
        const data = await response.json();
        this.setState({ dbcount: data.dbcount, indexcount: data.indexcount, loading: false });
    }


    render() {
        return (
            <div>
               {!this.state.loading && (<><p className="mt-2 mb-0">Databas innehåller {this.state.dbcount} gåvor</p> <p>Sökindex innehåller {this.state.indexcount} gåvor</p></>) }
            </div>
        )
    }
}