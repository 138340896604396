import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from './alz-logo-white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { SignOutButton } from '../SignOutButton';
import { AuthenticatedTemplate } from "@azure/msal-react";
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {

    return (
      <header>
        <Navbar className="navbar navbar-expand-lg navbar-dark bg-primary">
          <Container fluid={true}>
            <NavbarBrand tag={Link} to="/"><img className="logo" src={logo} alt="Alzheimerfonden logo"></img></NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} to="/" className="text-light" >Gåvoregistret</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/statistik">Statistik</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/kontakter">Kontakter</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/installningar" title='Inställningar' ><FontAwesomeIcon icon={faCog} /></NavLink>
                </NavItem>
                <AuthenticatedTemplate>
                  <NavItem>
                    <SignOutButton />
                  </NavItem>
                </AuthenticatedTemplate>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
