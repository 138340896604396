import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faEdit, faFilter, faPrint, faEnvelope, faInfoCircle, faGift, faHandHoldingUsd, faMonument, faQuestion, faFileExcel, faExclamationCircle, faAddressCard, faHandshake, faEye, faAt, faEnvelopeOpenText, faCreditCard, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import TextConversionService from '../../services/textConversionService';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { DonationEdit } from './DonationEdit';
import './DonationRegister.scss';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ExportService from '../../services/exportService';
import { DonationPrint } from './DonationPrint';
import { DonationSend } from './DonationSend';
import Spinner from 'react-bootstrap/Spinner';
import { CustomPagination } from '../pagination/CustomPagination';
import { DonationThankedStatus } from './DonationThankedStatus';
import { MsalContext } from "@azure/msal-react";
import { getAccessToken } from "../../services/authService";
import { Col, Row } from 'react-bootstrap';
import { DonationPaymentModal } from './DonationPaymentModalComponent';




export class DonationRegister extends Component {
  static displayName = DonationRegister.name;
  static contextType = MsalContext;

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.onLetterFilterChange = this.onLetterFilterChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onDonationTypeChange = this.onDonationTypeChange.bind(this);
    this.onDateFromChange = this.onDateFromChange.bind(this);
    this.onDateToChange = this.onDateToChange.bind(this);
    this.onTextSearchChange = this.onTextSearchChange.bind(this);
    this.onHonoraryPersonFilterClick = this.onHonoraryPersonFilterClick.bind(this);
    this.onImgFilterClick = this.onImgFilterClick.bind(this);
    this.onImgFilterClearClick = this.onImgFilterClearClick.bind(this);
    this.onDeliveryFilterClick = this.onDeliveryFilterClick.bind(this);
    this.onDeliveryFilterClearClick = this.onDeliveryFilterClearClick.bind(this);
    this.onHonoraryPersonFilterClearClick = this.onHonoraryPersonFilterClearClick.bind(this);
    this.isCheckedHandler = this.isCheckedHandler.bind(this);
    this.onCheckboxSelectChange = this.onCheckboxSelectChange.bind(this);
    this.onSelectDonations = this.onSelectDonations.bind(this);
    this.onEditDonation = this.onEditDonation.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.clearToast = this.clearToast.bind(this);
    this.exportToExcelHandler = this.exportToExcelHandler.bind(this);
    this.printSelectedGiftcertificates = this.printSelectedGiftcertificates.bind(this);
    this.printSelectedGiftcertificatesCallback = this.printSelectedGiftcertificatesCallback.bind(this);
    this.onPrintDonationHandler = this.onPrintDonationHandler.bind(this);
    this.sendSelectedGiftcertificates = this.sendSelectedGiftcertificates.bind(this);
    this.sendSelectedGiftcertificatesCallback = this.sendSelectedGiftcertificatesCallback.bind(this);
    this.onSendDonationHandler = this.onSendDonationHandler.bind(this);
    this.printSelectedAddressSheets = this.printSelectedAddressSheets.bind(this);
    this.refreshDonations = this.refreshDonations.bind(this);
    this.gotoPageCallback = this.gotoPageCallback.bind(this);
    this.updateThankedState = this.updateThankedState.bind(this);
    this.updateThankedStateCallback = this.updateThankedStateCallback.bind(this);
    this.updateState = this.updateState.bind(this);
    this.getImagePreview = this.getImagePreview.bind(this);
    this.addManualDonationEvent = this.addManualDonationEvent.bind(this);
    this.printPreviewSelectedGiftcertificates = this.printPreviewSelectedGiftcertificates.bind(this);
    this.getDeliverySymbol = this.getDeliverySymbol.bind(this);
    this.openPaymentModal = this.openPaymentModal.bind(this);
    this.onPaymentModalClose = this.onPaymentModalClose.bind(this);
    this.createInvoice = this.createInvoice.bind(this);
  }



  getInitialState() {
    const searchParams = new URLSearchParams(this.props.location.search);
    let initPageSize = '50';
    const pageSizeSearchParam = searchParams.get('pageSize');
    if (pageSizeSearchParam) {
      initPageSize = pageSizeSearchParam;
    }

    let initPage = '1';
    const pageSearchParam = searchParams.get('page');
    if (pageSearchParam) {
      initPage = pageSearchParam;
    }

    let dateFrom = new Date();
    dateFrom = dateFrom.setMonth(dateFrom.getMonth() - 6);
    const dateFromSearchParam = searchParams.get('dateFrom');
    if (dateFromSearchParam) {
      dateFrom = Date.parse(dateFromSearchParam);
    }


    let dateTo = new Date();
    dateTo = dateTo.setHours(23, 59, 59);
    const dateToSearchParam = searchParams.get('dateTo');
    if (dateToSearchParam) {
      dateTo = Date.parse(dateToSearchParam);
    }

    let textsearch = '';
    const textsearchParam = searchParams.get('textsearch');
    if (textsearchParam) {
      textsearch = textsearchParam;
    }

    let donationType = '';
    const donationTypeParam = searchParams.get('donationType');
    if (donationTypeParam) {
      donationType = donationTypeParam;
    }

    let donationState = '';
    const donationStateParam = searchParams.get('donationState');
    if (donationStateParam) {
      donationState = donationStateParam;
    }

    let letterFilter = '';
    const letterFilterParam = searchParams.get('letterFilter');
    if (letterFilterParam) {
      letterFilter = letterFilterParam;
    }

    let hpFilter = '';
    const hpFilterParam = searchParams.get('hp');
    if (hpFilterParam) {
      hpFilter = hpFilterParam;
    }

    let imgFilter = '';
    const imgFilterParam = searchParams.get("img");
    if (imgFilterParam) {
      imgFilter = imgFilterParam;
    }

    let deliveryFilter = '';
    const deliveryFilterParam = searchParams.get("delivery");
    if (deliveryFilterParam) {
      deliveryFilter = deliveryFilterParam;
    }

    const filters = {
      page: initPage,
      pageSize: initPageSize,
      dateFrom: TextConversionService.FormatDate(dateFrom),
      dateTo: TextConversionService.FormatDate(dateTo),
      textsearch: textsearch,
      donationType: donationType,
      donationState: donationState,
      letterFilter: letterFilter,
      hp: hpFilter,
      img: imgFilter,
      delivery: deliveryFilter,
      message: null
    }


    return { donations: [], loading: true, filters: filters, selectedDonations: [], showEditModal: false, activeDonation: null, showPaymentModal: false, paymentModalDonationId: null };
  }

  isHpFiltered() {
    return this.state.filters && typeof (this.state.filters.hp) !== 'undefined' && this.state.filters.hp !== '';
  }

  isImgFiltered() {
    return this.state.filters && typeof (this.state.filters.img) !== 'undefined' && this.state.filters.img !== '';
  }

  isDeliveryFiltered() {
    return this.state.filters && typeof (this.state.filters.delivery) !== 'undefined' && this.state.filters.delivery !== '';
  }

  isCheckedHandler(donationId) {
    return typeof (this.state.selectedDonations) !== 'undefined' && this.state.selectedDonations.includes(donationId);
  }

  printSelectedGiftcertificates(event) {
    if (this.state.selectedDonations.length > 0) {
      this.setState({ printDonations: true });
    }
  }

  async printPreviewSelectedGiftcertificates(event) {
    if (this.state.selectedDonations.length > 0) {
      await this.printSelectedGiftcertificatesCallback(event, true, false, true, false);
    }
  }

  async printSelectedAddressSheets(event) {
    if (this.state.selectedDonations.length > 0) {
      this.setState({ printAddresses: true });
      await ExportService.CreatePdf(this.state.selectedDonations, true, false, false, await getAccessToken(this.context));
      this.setState({ printAddresses: false });
    }
  }

  async printSelectedGiftcertificatesCallback(event, print, isEnvelope, showPictures, markAsSent) {
    this.setState({ busy: true });
    if (print && this.state.printDonationId) {
      await ExportService.CreatePdf([this.state.printDonationId], isEnvelope, showPictures, markAsSent, await getAccessToken(this.context));
      await this.refreshDonations([this.state.printDonationId]);
    }
    else if (this.state.selectedDonations.length > 0 && print) {
      await ExportService.CreatePdf(this.state.selectedDonations, isEnvelope, showPictures, markAsSent, await getAccessToken(this.context));
      await this.refreshDonations(this.state.selectedDonations);
    }
    this.setState({ printDonations: false, printDonationId: null, busy: false });
  }


  sendSelectedGiftcertificates(event) {
    if (this.state.selectedDonations.length > 0) {
      this.setState({ sendDonations: true });
    }
  }

  async sendSelectedGiftcertificatesCallback(event, send, recipient, markAsSent) {
    this.setState({ busy: true });
    if (send && this.state.sendDonationId) {

      let response = await ExportService.EmailDonations([this.state.sendDonationId], recipient, markAsSent, await getAccessToken(this.context));

      this.setState({ busy: false });
      const contentType = response.headers.get("content-type");

      if (!response.ok) {
        if (contentType && contentType.indexOf('json') > -1) {
          await this.displayErrorFromResponse(response);
        } else {
          await this.displayError("Ett okänt fel uppstod");
        }
        return;
      }
      await this.refreshDonations([this.state.sendDonationId]);
      this.setState({ message: "Gåvan skickad", sendDonations: false, sendDonationId: null });
      return;
    }
    else if (send && this.state.selectedDonations.length > 0) {
      let response = await ExportService.EmailDonations(this.state.selectedDonations, recipient, markAsSent, await getAccessToken(this.context));
      this.setState({ busy: false });
      const contentType = response.headers.get("content-type");
      if (!response.ok) {
        if (contentType && contentType.indexOf('json') > -1) {
          await this.displayErrorFromResponse(response);
        } else {
          await this.displayError("Ett okänt fel uppstod");
        }
        return;
      }
      await this.refreshDonations(this.state.selectedDonations);
      this.setState({ message: "Gåvor skickade", sendDonations: false, sendDonationId: null });
      return;
    }

    this.setState({ sendDonations: false, sendDonationId: null, busy: false });
  }

  async displayError(message) {
    this.setState({ errorMessage: message, sendDonations: false, sendDonationId: null });
  }

  async displayErrorFromResponse(response) {
    let data = await response.json();

    let errorMessage = "";

    if (data.errors) {
      errorMessage = data.errors[Object.keys(data.errors)[0]][0];
    } else {
      errorMessage = data[Object.keys(data)[0]][0];
    }
    this.setState({ errorMessage: errorMessage, sendDonations: false, sendDonationId: null });
  }


  async exportToExcelHandler(event) {
    if (this.state.selectedDonations.length > 0) {
      this.setState({ exportDonations: true, busy: true });
      await ExportService.DownloadFile(this.state.selectedDonations, await getAccessToken(this.context));
      this.setState({ exportDonations: false, busy: false });
    }
  }

  updateThankedState(event) {
    if (this.state.selectedDonations.length > 0) {
      this.setState({ setThankedStatus: true });
    }
  }

  async updateThankedStateCallback(event, shouldUpdate, thanked) {
    if (shouldUpdate && this.state.selectedDonations.length > 0) {
      this.setState({ busy: true });
      const data = {
        "thanked": thanked,
        "donationIds": this.state.selectedDonations
      };
      let response = await this.setThankedStatus(data);
      const contentType = response.headers.get("content-type");
      if (!response.ok && contentType.indexOf('json') > -1) {
        await this.displayErrorFromResponse(response);
        return;
      }
      await this.refreshDonations(this.state.selectedDonations);
      this.setState({ message: "Tackstatus är uppdaterad", setThankedStatus: false });
      return;
    }
    this.setState({ setThankedStatus: false, busy: false });
  }


  onCheckboxSelectChange(event, donationId) {
    let updatedSelectedDonations = [...this.state.selectedDonations];

    if (event.target.checked) {
      updatedSelectedDonations.push(donationId);
    } else {
      for (var i = 0; i < updatedSelectedDonations.length; i++) {

        if (updatedSelectedDonations[i] === donationId) {

          updatedSelectedDonations.splice(i, 1);
        }

      }
    }

    this.updateSelectedDonationsState(updatedSelectedDonations);
    return true;
  }

  onSelectDonations(eventKey, event) {
    if (eventKey === 'all') {
      let allSelected = this.state.donations.map((donation) => { return donation.id; });
      this.updateSelectedDonationsState(allSelected);
    } else {
      this.updateSelectedDonationsState([]);
    }
  }

  updateHistory() {
    let params = Object.assign({}, this.state.filters);
    let paramString = new URLSearchParams(params).toString();
    this.props.history.push(this.props.history.location.pathname + '?' + paramString);
  }

  async componentDidMount() {
    await this.loadDonations(this.state.filters.page, this.state.filters.pageSize);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.queryChanged(prevState)) {
      await this.loadDonations(this.state.filters.page, this.state.filters.pageSize);
      this.updateHistory();
    }
  }

  queryChanged(prevState) {
    return this.state.filters.page !== prevState.filters.page ||
      this.state.filters.pageSize !== prevState.filters.pageSize ||
      this.state.filters.dateFrom !== prevState.filters.dateFrom ||
      this.state.filters.dateTo !== prevState.filters.dateTo ||
      this.state.filters.donationType !== prevState.filters.donationType ||
      this.state.filters.donationState !== prevState.filters.donationState ||
      this.state.filters.letterFilter !== prevState.filters.letterFilter ||
      this.state.filters.textsearch !== prevState.filters.textsearch ||
      this.state.filters.hp !== prevState.filters.hp ||
      this.state.filters.img !== prevState.filters.img ||
      this.state.filters.delivery !== prevState.filters.delivery;
  }

  onTextSearchChange(event) {
    if (event.keyCode === 13) {
      this.updateState('textsearch', event.target.value, '1');
    }
  }

  getImagePreview(imageType, imageReference, donationReference) {
    if (!imageReference) {
      return (<></>)
    }
    let imageUrl = `/api/image/${imageType === 'standard' ? 'standard' : `custom/${donationReference}`}/${imageReference}`;
    return (
      <button onClick={(event) => this.onImgFilterClick(event, imageReference)} className='btn btn-link' data-imgid={imageReference}><img src={imageUrl} alt='' /></button>
    )
  }

  getDeliverySymbol(donationDelivery) {
    if (!donationDelivery || donationDelivery === 0) {
      return (<></>);
    }

    return (<button className='btn btn-link' onClick={(event) => this.onDeliveryFilterClick(donationDelivery)} data-delivery={donationDelivery} >{DonationRegister.deliverySymbol(donationDelivery)}</button>);
  }

  dateFromTimer = null;
  onDateFromChange(event) {
    let self = this;
    let newdate = event.target.value;
    if (this.dateFromTimer) {
      clearTimeout(this.dateFromTimer);
    }
    this.dateFromTimer = setTimeout(() => {
      self.updateState('dateFrom', newdate, '1');
    }, 400);
  }

  dateToTimer = null;
  onDateToChange(event) {
    let self = this;
    let newdate = event.target.value;
    if (this.dateToTimer) {
      clearTimeout(this.dateToTimer);
    }
    this.dateToTimer = setTimeout(() => {
      self.updateState('dateTo', newdate, '1');
    }, 400);
  }

  onDonationTypeChange(event) {
    this.updateState('donationType', event.target.value, '1');
  }

  onStatusChange(event) {
    this.updateState('donationState', event.target.value, '1');
  }

  onLetterFilterChange(event) {
    this.updateState('letterFilter', event.target.value, '1');

  }

  onPageSizeChange(event) {
    this.updateState('pageSize', event.target.value, '1');
  }

  onHonoraryPersonFilterClick(event) {
    this.updateState('hp', event.target.dataset.hp, '1', 'textsearch');
  }

  onImgFilterClick(event, imageReference) {
    this.updateState('img', imageReference, '1');
  }

  onDeliveryFilterClick(delivery) {
    this.updateState('delivery', delivery.toString(), '1');
  }

  onDeliveryFilterClearClick(delivery) {
    this.updateState('delivery', '', '1');
  }

  onImgFilterClearClick(event) {
    this.updateState('img', '', '1');
  }

  onHonoraryPersonFilterClearClick(event) {
    this.updateState('hp', '', '1');
  }

  onEditDonation(event, donationId) {
    let foundItems = this.state.donations.filter(donation => { return donation.id === donationId; });
    if (typeof (foundItems) !== 'undefined' && foundItems.length === 1) {
      this.updateModalState(true, foundItems[0], null, null, false);
    }
  }

  onPrintDonationHandler(event, donationId) {
    if (donationId > 0) {
      this.setState({ printDonations: true, printDonationId: donationId });
    }
  }

  openPaymentModal(donationId) {
    if (donationId > 0) {
      this.setState({ showPaymentModal: true, paymentModalDonationId: donationId });
    }
  }

  async createInvoice(donationId) {
    console.log("inv", donationId);
    if (donationId > 0) {
      let url = new URL(`api/donationedit/payment/invoice/${donationId}`, document.baseURI);
      const response = await fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        body: '',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getAccessToken(this.context)}`
        }
      }).catch(error => {
        console.log("Error creating invoice ", error)
        this.setState({ errorMessage: "Ett okänt fel uppstod, inbetalningsavi har inte skapats" });
      });

      if (response.ok) {
        const data = await response.json();
        if (data && data.error) {
          this.setState({ errorMessage: data.error });
        } else {
          this.setState({ message: "Inbetalningsavi har skapats och gåvan har uppdaterats" });
          await this.loadDonations(this.state.filters.page, this.state.filters.pageSize);
        }
      } else {
        this.setState({ errorMessage: "Ett okänt fel uppstod, inbetalningsavi har inte skapats" });
      }
    }
  }


  async onPaymentModalClose(updatedDonationId, message) {
    this.setState({ showPaymentModal: false, paymentModalDonationId: null, message: message });
    let donations = [...this.state.donations];
    let donationFound = false;
    donations.forEach(function (d, idx) { if (d.id === updatedDonationId) { donationFound = true; } });
    if (!donationFound) {
      await this.loadDonations(this.state.filters.page, this.state.filters.pageSize);
    }
  }

  onSendDonationHandler(event, donationId) {
    if (donationId > 0) {
      this.setState({ sendDonations: true, sendDonationId: donationId });
    }
  }

  async handleModalClose(updatedDonation, message, newDonation) {
    console.log("mc", updatedDonation);
    await this.updateModalState(false, null, updatedDonation, message, false);
    if (newDonation && updatedDonation && updatedDonation.donationState === 2 && updatedDonation.paymentMethod === 'card') {
      console.log("try card");
      this.setState({ showPaymentModal: true, paymentModalDonationId: updatedDonation.id });
    }
    if (updatedDonation && updatedDonation.donationState === 2 && updatedDonation.paymentMethod === 'invoice' && updatedDonation.donorCompanyName === '') {
      console.log("try invoice");
      await this.createInvoice(updatedDonation.id);
    }

  }



  updateSelectedDonationsState(currentSelection) {
    this.setState({ donations: this.state.donations, loading: this.state.loading, filters: this.state.filters, selectedDonations: currentSelection, showEditModal: this.state.showEditModal, activeDonation: this.state.activeDonation });
  }

  async updateModalState(showModal, selectedDonation, updatedDonation, message, newDonation) {
    let donations = [...this.state.donations];
    if (typeof (updatedDonation) !== 'undefined' && updatedDonation !== null) {
      let donationFound = false;
      donations.forEach(function (d, idx) { if (d.id === updatedDonation.id) { donationFound = true; donations[idx] = updatedDonation; } });
      if (!donationFound) {
        await this.loadDonations(this.state.filters.page, this.state.filters.pageSize);
      }
    }

    this.setState({ donations: donations, loading: this.state.loading, filters: this.state.filters, selectedDonations: this.state.selectedDonations, showEditModal: showModal, activeDonation: selectedDonation, message: message, newDonation: newDonation });
  }


  clearToast(event) {
    this.setState({ message: null, errorMessage: null });
  }

  gotoPageCallback(gotoPage) {
    if (gotoPage) {
      this.updateState(null, null, gotoPage);
    }
  }

  updateState(key, value, page, clearKey) {
    let updatedFilters = Object.assign({}, this.state.filters);
    if (key) {
      updatedFilters[key] = value;
    }

    if (clearKey) {
      updatedFilters[clearKey] = '';
    }

    if (page) {
      updatedFilters['page'] = page;
    }
    //this.setState({ donations: this.state.donations, loading: this.state.loading, filters: updatedFilters, selectedDonations: this.state.selectedDonations, showEditModal: this.state.showEditModal, activeDonation: this.state.activeDonation });

    this.setState((prevState) => {
      return { filters: updatedFilters };
    });

  }

  static deliverySymbol(donationDelivery) {
    let icon = null;
    switch (parseInt(donationDelivery)) {
      case 2:
        icon = faAt;
        break;
      case 1:
        icon = faEnvelopeOpenText;
        break;
      default:
        break;
    }

    if (icon !== null) {
      return <span title={donationDelivery === 2 ? 'Digital leverans' : 'Utskrivet blad'}><FontAwesomeIcon icon={icon} /></span>
    }

    return (<></>)
  }

  static donationSymbol(donationType) {
    let icon = faQuestion;
    switch (parseInt(donationType)) {
      case 0:
        icon = faMonument;
        break;
      case 1:
        icon = faGift;
        break;
      case 2:
        icon = faHandHoldingUsd;
        break;
      default:
        break;
    }

    return (
      <div className={`donation-symbol donation-${donationType}`} title={TextConversionService.DonationTypeName(donationType)} ><FontAwesomeIcon icon={icon} /></div>
    )
  }

  static renderDonationsTable(donations, hpFilterButtonHandler, isFiltered, clearFilterHandler, isCheckedHandler, checkChangedHandler, selectDonationsHandler, onEditDonationHandler, onPrintDonationHandler, onSendDonationHandler, getImagePreview, clearImgFilterHandler, isImgFiltered, getDeliverySymbol, isDeliveryFiltered, clearDeliveryFilterHandler, openPaymentModal, createInvoice) {
    return (
      <table className='table table-striped table-hover' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th className='text-center'>
              <Dropdown onSelect={selectDonationsHandler} >
                <Dropdown.Toggle id="dd-select-donations" >Välj</Dropdown.Toggle>
                <Dropdown.Menu >
                  <Dropdown.Item eventKey="all" >Alla</Dropdown.Item>
                  <Dropdown.Item eventKey="none">Ingen</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th>Hantera</th>
            <th title='Leveranssätt'>Lev. {isDeliveryFiltered && <button onClick={clearDeliveryFilterHandler} className="btn btn-link btn-sm"><FontAwesomeIcon icon={faFilter} /></button>}</th>
            <th className='no-wrap'>Mall {isImgFiltered && <button onClick={clearImgFilterHandler} className="btn btn-link btn-sm"><FontAwesomeIcon icon={faFilter} /></button>}</th>
            <th>Givare</th>
            <th>Gåvonummer</th>
            <th>Gåvotyp</th>
            <th className='text-center'>Händelsedatum</th>
            <th className='no-wrap'>Hedersperson {isFiltered && <button onClick={clearFilterHandler} className="btn btn-link btn-sm"><FontAwesomeIcon icon={faFilter} /></button>}</th>
            <th>Mottagare</th>
            <th>Skickas till</th>
            <th className='text-center'>Datum</th>
            <th>Summa</th>
            <th>Skickad</th>
            <th>Betalmedel</th>
            <th>Status</th>
            <th>Kommentar</th>
          </tr>
        </thead>
        <tbody>
          {donations && donations.length > 0 && donations.map(donation =>
            <tr key={donation.id} className={donation.sent && parseInt(donation.donationState) === 3 ? 'table-success' : ''} >
              <td className='text-center'><input type="checkbox" name="donationId" value={donation.id} checked={isCheckedHandler(donation.id)} onChange={(event) => checkChangedHandler(event, donation.id)} ></input></td>
              <td ><div className='button-container'><Button title='Redigera gåva' onClick={(event) => onEditDonationHandler(event, donation.id)} variant='outline-primary'><FontAwesomeIcon icon={faEdit} /></Button><Button onClick={(event) => onPrintDonationHandler(event, donation.id)} title='Skriv ut gåvoblad' variant='outline-secondary'><FontAwesomeIcon icon={faPrint} /></Button><Button onClick={(event) => onSendDonationHandler(event, donation.id)} title='Skicka gåvoblad' variant='outline-dark'><FontAwesomeIcon icon={faEnvelope} /></Button></div></td>
              <td className='text-center' >{getDeliverySymbol(donation.donationDelivery)}</td>
              <td>{getImagePreview(donation.imageType, donation.imageReference, donation.externalReference)}</td>
              <td>{donation.donorName}{donation.donorCompanyName && <small className='d-block'>{donation.donorCompanyName}</small>}</td>
              <td className='extref-col no-wrap' title={donation.externalReference ?? donation.id} onClick={event => navigator.clipboard.writeText(event.target.title)} >{donation.externalReference ?? donation.id}</td>
              <td className='text-center'>{DonationRegister.donationSymbol(donation.donationType)}<span className='sr-only'>{TextConversionService.DonationTypeName(donation.donationType)}</span></td>
              <td className='text-center no-wrap'>{donation.honoraryDateUtc ? TextConversionService.FormatDate(new Date(donation.honoraryDateUtc)) : ''}</td>
              <td><button data-hp={TextConversionService.B64Encode(`${donation.honoraryFirstName}|${donation.honoraryLastName}`)} onClick={hpFilterButtonHandler} className="btn btn-link">{donation.honoraryName}</button></td>
              <td>{donation.recipient}</td>
              <td dangerouslySetInnerHTML={{ __html: TextConversionService.FormatForWeb(donation.recipientAddress, ", ", "<br />") }}></td>
              <td className='text-center no-wrap'>{TextConversionService.FormatDateTime(new Date(donation.dateCreatedUtc))}</td>
              <td className='no-wrap'>
                {donation.amount} kr
                {donation.donationState === 2 && donation.paymentMethod === 'card' && (<><br /><Button variant="outline-secondary" size='sm' onClick={event => openPaymentModal(donation.id)}><FontAwesomeIcon icon={faCreditCard}></FontAwesomeIcon><span className='visually-hidden'>Betala gåva med kort</span></Button></>)}
                {donation.donationState === 2 && donation.paymentMethod === 'invoice' && (<><br /><Button variant="outline-secondary" size='sm' onClick={async (event) => await createInvoice(donation.id)}><FontAwesomeIcon icon={faFileInvoice}></FontAwesomeIcon><span className='visually-hidden'>Betala gåva med inbetalningsavi</span></Button></>)}
              </td>
              <td>{donation.sent ? "Ja" : "Nej"}</td>
              <td>{TextConversionService.PaymentType(donation.paymentMethod, donation.donorCompanyName)}</td>
              <td>{TextConversionService.DonationStateName(donation.donationState)}</td>
              <td>{donation.comments}</td>
            </tr>
          )}
          {
            (!donations || donations.length === 0) &&
            <tr>
              <td colSpan='14' className='text-center'>Inga gåvor laddades</td>
            </tr>
          }
        </tbody>
      </table>
    );
  }

  addManualDonationEvent(event) {
    this.updateModalState(true, null, null, null, true);
  }

  renderDonationEditModal() {
    return (
      <>
        {(this.state.newDonation || this.state.activeDonation) &&
          <DonationEdit donationId={this.state.activeDonation?.id} show={this.state.showEditModal} notifyParent={this.handleModalClose} newDonation={this.state.newDonation} ></DonationEdit>}

      </>
    );
  }

  renderToast(message, header, error = false) {
    let css = error ? 'danger' : 'success';
    let icon = error ? faExclamationCircle : faInfoCircle;
    return (
      <Toast onClose={this.clearToast} delay={3000} autohide bg={css} >
        <Toast.Header>
          <FontAwesomeIcon icon={icon} className={error ? 'text-danger' : 'text-success'}></FontAwesomeIcon>
          {header && <strong className="ps-2 me-auto">{header}</strong>}
        </Toast.Header>
        <Toast.Body className='text-white'>{message}</Toast.Body>
      </Toast>
    );
  }

  renderPaymentModal() {
    return (
      <>
        {this.state.showPaymentModal &&
          <DonationPaymentModal show={this.state.showPaymentModal} donationId={this.state.paymentModalDonationId} notifyParent={this.onPaymentModalClose} ></DonationPaymentModal>
        }
      </>
    )
  }

  renderPrintModal() {
    return (
      <>
        {this.state.printDonations &&
          <DonationPrint show={this.state.printDonations} notifyParent={this.printSelectedGiftcertificatesCallback} />
        }
      </>
    )

  }

  renderSendModal() {
    return (
      <>
        {this.state.sendDonations &&
          <DonationSend show={this.state.sendDonations} notifyParent={this.sendSelectedGiftcertificatesCallback} />
        }
      </>
    )
  }

  renderThankStatusModal() {
    return (
      <>
        {this.state.setThankedStatus && <DonationThankedStatus show={this.state.setThankedStatus} notifyParent={this.updateThankedStateCallback} />}
      </>
    )
  }


  renderfilters() {
    return (
      <div className='filters'>
        <div className='col-auto col-2'>
          <label htmlFor='namesearch'>Sök gåvor</label>
          <input type='text' name='namesearch' defaultValue={this.state.filters.textsearch} onKeyDown={this.onTextSearchChange} className='form-control' id='namesearch' placeholder='Ange söktext'></input>
        </div>
        <div className='col-auto'>
          <label htmlFor='letterFilter'>Bokstav</label>
          <select value={this.state.filters.letterFilter} className='form-select' onChange={this.onLetterFilterChange} id='letterFilter'>
            <option value="">Inget bokstavsintervall</option>
            <option>A-I</option>
            <option>J-S</option>
            <option>T-Ö</option>
          </select>
        </div>
        <div className='col-auto'>
          <label htmlFor='donationType'>Gåvotyp</label>
          <select value={this.state.filters.donationType} className='form-select' onChange={this.onDonationTypeChange} id='donationType'>
            <option value="">Alla g&#229;votyper</option>
            <option value="Memory">Minnesg&#229;va</option>
            <option value="Celebration">Gratulationsg&#229;va</option>
            <option value="OneTime">Eng&#229;ngsg&#229;va</option>
          </select>
        </div>
        <div className='col-auto'>
          <label htmlFor='dateFrom'>Datum (från - till)</label>
          <div className='inline-elements'>
            <input type='date' className='form-control' value={this.state.filters.dateFrom} name='dateFrom' id='dateFrom' onChange={this.onDateFromChange} ></input>
            <input type='date' className='form-control' value={this.state.filters.dateTo} name='dateTo' id='dateTo' onChange={this.onDateToChange}></input>
          </div>
        </div>
        <div className='col-auto'>
          <label htmlFor='donationState'>Status</label>
          <select value={this.state.filters.donationState} className='form-select' onChange={this.onStatusChange} id='donationState'>
            <option value="">Alla</option>
            <option value="Delivered">Skickad</option>
            <option value="NotDelivered">Ej skickad</option>
            <option value="Thanked">Tackad</option>
            <option value="NotThanked">Ej tackad, g&#229;va &#246;ver 1000kr</option>
          </select>
        </div>
        <div className='col-auto'>
          <label htmlFor='donationPageSize'>Visa</label>
          <select value={this.state.filters.pageSize} className='form-select' onChange={this.onPageSizeChange} id='donationPageSize'>
            <option>20</option>
            <option>50</option>
            <option>100</option>
            <option>250</option>
          </select>
        </div>
      </div>
    );
  }

  isProcessing() {
    return this.state.printAddresses || this.state.printDonations || this.state.sendDonations || this.state.exportDonations || this.state.setThankedStatus || this.state.busy;
  }

  isBusy() {
    return this.state.busy;
  }


  render() {
    let contents = this.state.loading
      ? <p><em>Laddar gåvor...</em></p>
      : DonationRegister.renderDonationsTable(this.state.donations, this.onHonoraryPersonFilterClick, this.isHpFiltered(), this.onHonoraryPersonFilterClearClick, this.isCheckedHandler, this.onCheckboxSelectChange, this.onSelectDonations, this.onEditDonation, this.onPrintDonationHandler, this.onSendDonationHandler, this.getImagePreview, this.onImgFilterClearClick, this.isImgFiltered(), this.getDeliverySymbol, this.isDeliveryFiltered(), this.onDeliveryFilterClearClick, this.openPaymentModal, this.createInvoice);

    let filters = this.renderfilters();

    let editDonationModal = this.renderDonationEditModal();

    let printModal = this.renderPrintModal();
    let sendModal = this.renderSendModal();
    let thankStatusModal = this.renderThankStatusModal();
    let donationPaymentModal = this.renderPaymentModal();

    return (
      <Fragment>
        <ToastContainer className="p-2 fixed-top mx-auto">
          {this.state.message && this.renderToast(this.state.message, "Gåvoregister")}
          {this.state.errorMessage && this.renderToast(this.state.errorMessage, "Ett fel uppstod", true)}
        </ToastContainer>
        <Container fluid={true}>
          <Row>
            <Col md={1}>
              <div style={{ "height": "100%" }} className='d-flex align-items-center'>
                <button title='Registrera gåva manuellt' onClick={this.addManualDonationEvent} className='btn btn-success'><FontAwesomeIcon icon={faPlusCircle} /></button>
              </div>
            </Col>
            <Col md={11}>
              {filters}
            </Col>
          </Row>
        </Container>
        <div>
          {contents}
          {this.state.totalFound > 0 && <CustomPagination totalFound={this.state.totalFound} currentPage={this.state.filters.page} pageSize={this.state.filters.pageSize} gotoPageCallback={this.gotoPageCallback} ></CustomPagination>}
        </div>
        {this.state.selectedDonations.length > 0 && <Navbar fixed="bottom" bg="light" variant="light" >
          <Container fluid={true} >
            <Navbar.Brand as='span'>{`${this.state.selectedDonations.length} gåvor valda`}</Navbar.Brand>
            <Navbar.Collapse className="justify-content-center action-btns">
              <Button disabled={this.state.selectedDonations.length === 0 || this.isProcessing()} variant="primary" onClick={this.printSelectedGiftcertificates} ><FontAwesomeIcon icon={faPrint} /> Skriv ut gåvoblad</Button>
              <Button disabled={this.state.selectedDonations.length === 0 || this.isProcessing()} variant="outline-primary" onClick={this.printPreviewSelectedGiftcertificates} ><FontAwesomeIcon icon={faEye} /> Förhandsgranska gåvoblad</Button>
              <Button disabled={this.state.selectedDonations.length === 0 || this.isProcessing()} variant="secondary" onClick={this.printSelectedAddressSheets} ><FontAwesomeIcon icon={faAddressCard} /> Skriv ut adresser {this.state.printAddresses && <Spinner animation="border" variant="primary" size="sm" ><span className="visually-hidden">Skapar adresser...</span></Spinner>} </Button>
              <Button disabled={this.state.selectedDonations.length === 0 || this.isProcessing()} variant="outline-dark" onClick={this.sendSelectedGiftcertificates} ><FontAwesomeIcon icon={faEnvelope} /> Skicka gåvoblad</Button>
              <Button disabled={this.state.selectedDonations.length === 0 || this.isProcessing()} variant="success" className='success-alt' onClick={this.exportToExcelHandler} ><FontAwesomeIcon icon={faFileExcel} /> Exportera till Excel {this.state.exportDonations && <Spinner animation="border" variant="primary" size="sm" ><span className="visually-hidden">Skapar adresser...</span></Spinner>}</Button>
              <Button disabled={this.state.selectedDonations.length === 0 || this.isProcessing()} variant="outline-info" onClick={this.updateThankedState} ><FontAwesomeIcon icon={faHandshake} /> Uppdatera tackstatus</Button>
            </Navbar.Collapse>
            {this.isBusy() && <Spinner animation="border" variant="primary" />}
          </Container>
        </Navbar>}
        {editDonationModal}
        {printModal}
        {sendModal}
        {thankStatusModal}
        {donationPaymentModal}
      </Fragment>
    );
  }

  async loadDonations(page, pageSize) {
    let url = new URL('api/donationregister', document.baseURI);
    let params = Object.assign({}, this.state.filters);
    params.page = page;
    url.search = new URLSearchParams(params).toString();

    const myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');
    myHeaders.append('Authorization', `Bearer ${await getAccessToken(this.context)}`);

    const init = {
      method: 'GET',
      headers: myHeaders,
    };

    const response = await fetch(url, init);
    const data = await response.json();
    this.setState({ donations: response.ok ? data.items : [], totalFound: response.ok ? parseInt(data.totalFound) : 0, loading: false, filters: params });
  }

  async setThankedStatus(thankedStatus) {
    let url = new URL('api/donationedit/thankedstate', document.baseURI);
    var myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${await getAccessToken(this.context)}`);


    const data = {
      "thanked": thankedStatus.thanked,
      "donationIds": thankedStatus.donationIds
    };
    const init = {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify(data)
    };

    const response = await fetch(url, init);
    return response;
  }

  async refreshDonations(idsToRefresh) {
    let url = new URL('api/donationregister', document.baseURI);
    var myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${await getAccessToken(this.context)}`);

    const data = {
      "donationIds": idsToRefresh
    };
    const init = {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify(data)
    };

    const response = await fetch(url, init);
    if (response.ok) {
      const data = await response.json();
      let donations = [...this.state.donations];
      data.forEach(function (updatedDonation, updateIdx) {
        if (typeof (updatedDonation) !== 'undefined' && updatedDonation !== null) {
          donations.forEach(function (d, idx) { if (d.id === updatedDonation.id) { donations[idx] = updatedDonation; } });
        }
      });

      this.setState({ donations: donations });
    }
  }

}
